import React, { FC, useEffect, useState } from 'react';

import { Col, Form, Row } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { layout, LayoutProps, space, SpaceProps } from 'styled-system';

import styled from '@emotion/styled';

import { B, Div, SwitchComponent, Divider } from '../../../components';
import theme from '../../../theme';

const MainDiv = styled.div`
  padding: 32px;
  .ant-form-item-label > label {
    font-size: 14px;
    font-weight: 600;
    color: ${theme.blue700};
  }
  .ant-form-item-label {
    padding: 0px 0px 4px;
  }
  @media (max-width: 768px) {
    padding: 24px 10px;
    width: 100%;
  }
`;

const FormItem = styled(Form.Item)<SpaceProps & LayoutProps>`
  margin-bottom: 0px;
  ${space}
  ${layout}
`;
interface Props {
  form: FormInstance<any>;
  isFourDecimalPoint?: boolean | null;
}
const FourDecimalPoint: FC<Props> = ({ form, isFourDecimalPoint }) => {
  const [
    isFourDecimalPointEnable,
    setIsFourDecimalPointEnable,
  ] = useState<boolean>(false);

  useEffect(() => {
    if (isFourDecimalPoint) {
      setIsFourDecimalPointEnable(isFourDecimalPoint);
    }
  }, [isFourDecimalPoint]);

  return (
    <>
      <MainDiv>
        <B type="b-large-semibold" pb="16px">
          Input values with four decimal places
        </B>
        <Row>
          <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
            <Div display="flex">
              <FormItem name="isFourDecimalPoint" valuePropName="checked">
                <SwitchComponent
                  onChange={(checked: boolean) => {
                    setIsFourDecimalPointEnable(checked);
                  }}
                />
              </FormItem>
              <B
                type="b-default"
                color={theme.gray600}
                pl="10px"
                pt="5px"
                pr="16px">
                Enable four decimal places
              </B>
            </Div>
          </Col>
          <Col xs={24} sm={24} md={18} lg={18} xl={18} xxl={18}>
            {isFourDecimalPointEnable && (
              <B type="b-small" color={theme.gray600} pt="5px" pl="10px">
                By switching on this toggle you will be able to input values
                with four decimal places and your outputs will be rounded off to
                two decimal places at the end.
              </B>
            )}
          </Col>
        </Row>
      </MainDiv>

      <Divider />
    </>
  );
};

export default FourDecimalPoint;
