import axios, { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQuery } from 'react-query';
import {
  EditSystemUpdateI,
  OrganizationsDataByOwnerI,
  SystemUpdateI,
} from '../types/superAdmin.types';
import { UserI } from '../types/people.types';
import { message } from 'antd';
import { getApiError } from '../util/getApiError';
import { OrganizationOwnersDetailsI } from '../types/superAdmin.types';
import { DayCountI } from '../types/superAdmin.types';

export const useGetOrganizationData = () => {
  return useMutation<OrganizationsDataByOwnerI[], AxiosError, number>(
    async (accountId) => {
      const response: AxiosResponse<OrganizationsDataByOwnerI[]> =
        await axios.get(
          `/super-admin/getOrganizationsDataByOwner/${accountId}`,
        );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
    },
  );
};

export const useGetSystemUpdates = () => {
  return useQuery<SystemUpdateI[], AxiosError>(
    'system-updates',
    async () => {
      const response: AxiosResponse<SystemUpdateI[]> = await axios.get(
        '/super-admin/all-system-updates',
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
    },
  );
};

export const useGetSystemUpdate = () => {
  return useMutation<SystemUpdateI, AxiosError, number>(
    async (id) => {
      const response: AxiosResponse<SystemUpdateI> = await axios.get(
        `/super-admin/system-update/${id}`,
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
    },
  );
};

export const useCreateSystemUpdate = () => {
  return useMutation<string, AxiosError, SystemUpdateI>(
    async (data: SystemUpdateI) => {
      const response: AxiosResponse<string> = await axios.post(
        '/super-admin/add-new-system-update',
        data,
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
      onSuccess: () => {
        message.success('New system update added successfully');
      },
    },
  );
};

export const useEditSystemUpdate = () => {
  return useMutation<string, AxiosError, EditSystemUpdateI>(
    async ({ id, data }) => {
      const response: AxiosResponse<string> = await axios.put(
        `/super-admin/edit-system-updates/${id}`,
        data,
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
      onSuccess: () => {
        message.success('Updated Successfully');
      },
    },
  );
};

export const useDeleteSystemUpdate = () => {
  return useMutation<string, AxiosError, Number>(
    async (id: Number) => {
      const response: AxiosResponse<string> = await axios.delete(
        `super-admin/delete-system-updates/${id}`,
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
      onSuccess: () => {
        message.success('System update deleted successfully');
      },
    },
  );
};

export const useGetAllOwnersDetails = () => {
  return useQuery<any[], AxiosError>(
    'owners-details',
    async () => {
      const response: AxiosResponse<any[]> = await axios.get(
        '/super-admin/organizationsDetails',
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
    },
  );
};

export const useGetOrganizationOwnersDetails = () => {
  return useMutation<OrganizationOwnersDetailsI[], AxiosError, DayCountI>(
    async (data: DayCountI) => {
      try {
        const response: AxiosResponse<OrganizationOwnersDetailsI[]> =
          await axios.get(
            `/super-admin/organizationOwnersDetails/${data.startDate}/${data.endDate}`,
          );
        return response.data;
      } catch (error) {
        console.error('Error while getting data:', error);
        throw error;
      }
    },
  );
};
