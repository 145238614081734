import React, { FC, useEffect, useState } from 'react';
import { Col, Row, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import {
  useGetAllPayrollDetails,
  usePayslipSend,
  useUpdateWorkedDays,
} from '../../../../api/payrollHooks';
import {
  B,
  DefaultBody,
  Div,
  LinkButton,
  PrimaryButton,
  SecondaryButton,
} from '../../../../components';
import { BannerAlert } from '../../../../components/alertMessage/BannerAlert';
import { FooterComponent } from '../../../../components/FooterComponent';
import { DispatchType, RootState } from '../../../../store/reducer';
import { EmpPayrollI, getLeaveBalanceByUser } from '../../../../store/actions';
import {
  PayrollItemTypeEnum,
  SalaryInformationView,
} from '../../../../types/payroll.types';
import { getDesignation } from '../../../people/PeopleUtil';
import { CollapseItems } from './CollapseItems';
import { PaySlipHeader } from './PaySlipHeader';
import { PersonalDetails } from './PersonalDetails';
import PersonalSalarySlipTable from './PersonalSalarySlip';
import SpecialNote from './SpecialNote';
import theme from '../../../../theme';
import { numberThousandSeparator } from '../../../../util/utils';
import Noticecard from '../../../../components/NoticeCard';
import { Attendance } from './Attendance';
import {
  EmployeePaySlipSummary,
  payrollParam,
} from '../../../../types/payslipPageTypes';
import { WorkedDaysForm } from './WorkedDaysForm';

const PaySlipPage: FC = () => {
  const navigate = useNavigate();
  const dispatch: DispatchType = useDispatch();
  const [sheetIndex, setSheetIndex] = useState(0);
  const params = useParams<payrollParam>();
  const [dataSource, setDataSource] = useState<EmployeePaySlipSummary[]>([]);
  const [isCompletePayroll, setIsCompletePayroll] = useState<boolean>(false);
  const [visible, setVisible] = useState(false);

  const organizationData = useSelector(
    (state: RootState) => state.organization.organizationData,
  );

  const {
    data: payrollDetails = null,
    mutate: getAllPayrollDetails,
    isLoading: allPayrollDetailsLoading,
  } = useGetAllPayrollDetails();

  const userPayrollDetail = payrollDetails?.empPayroll.find(
    (item) => item.userId === Number(params.employeeKey),
  );
  const expensePayitem = userPayrollDetail?.payrollItems.find(
    (items) => items.type === PayrollItemTypeEnum.EXPENSES,
  );

  const { mutate: postSendPayslip } = usePayslipSend();

  useEffect(() => {
    if (params.payrollKey) {
      getAllPayrollDetails(params.payrollKey);
    }
  }, []);

  useEffect(() => {
    if (userPayrollDetail?.userId) {
      dispatch(getLeaveBalanceByUser(userPayrollDetail.userId.toString()));
    }
  }, [userPayrollDetail?.userId]);

  const employeeSelectorMap = (data: EmpPayrollI) => {
    return {
      payRollId: data.id,
      netSalary: data.netSalary,
      name: data.user?.fullName
        ? data.user?.fullName
        : data.user?.preferredName || '',
      userId: Number(data.user?.id),
      employeeNumber: data.user.employeeNumber,
      designation: getDesignation(data.user.userHistory) || null,
      joinedDate: data.user.joinedDate,
      resignedDate: data.user.resignedDate,
      isProRata: data.isProRata,
      proRataDays: data.proRataDays,
      isSecondary: data.user.isSecondary,
      isEmployeePaidInCash: data.user.isEmployeePaidInCash,
      attendance: data?.attendance,
    };
  };

  useEffect(() => {
    setIsCompletePayroll(payrollDetails?.status === 'COMPLETED');
    if (!isCompletePayroll) {
      const employeeSelectorData = payrollDetails?.empPayroll.map((data) =>
        employeeSelectorMap(data),
      );
      setDataSource(employeeSelectorData || []);
    } else {
      const confirmEmployeeSelectorData = payrollDetails?.empPayroll
        .filter((item) => item.isSelected === true)
        .map((data) => employeeSelectorMap(data));
      setDataSource(confirmEmployeeSelectorData || []);
    }
  }, [payrollDetails]);

  useEffect(() => {
    setSheetIndex(
      dataSource.findIndex((x) => x.userId == Number(params.employeeKey)),
    );
  }, [dataSource, sheetIndex]);

  const downloadPaySlip = () => {
    window.open(userPayrollDetail?.payslipUrl, '_blank');
  };

  const {
    isLoading: isLoadingUpdateWorkedDays,
    mutate: updateWorkedDays,
    data: dataUpdateWorkedDays = null,
    error: errorUpdateWorkedDays,
    isSuccess: isUpdateWorkDaysSuccess,
  } = useUpdateWorkedDays();

  const submitWorkedDays = async (values: { workedDays: string }) => {
    try {
      if (payrollDetails && userPayrollDetail) {
        updateWorkedDays({
          empPayrollId: userPayrollDetail.id,
          workedDays: Number(values.workedDays),
          payrollId: payrollDetails.id,
        });
      }
    } catch (error) {
      console.error('Something wrong with updating worked days.');
    }
  };

  useEffect(() => {
    if (
      dataUpdateWorkedDays?.workedDays &&
      isUpdateWorkDaysSuccess &&
      !errorUpdateWorkedDays
    ) {
      getAllPayrollDetails(params.payrollKey);

      dispatch(getLeaveBalanceByUser(params.employeeKey));
    }
  }, [isUpdateWorkDaysSuccess]);

  return (
    <>
      <Spin spinning={allPayrollDetailsLoading || isLoadingUpdateWorkedDays}>
        <PaySlipHeader payrollDetails={payrollDetails} />
        {!userPayrollDetail?.user.paySlipMail && (
          <BannerAlert
            message="Please note that this employee doesn't have a payslip email!"
            AlertTypes="warning"
            closable
            showIcon={true}
            action={
              isCompletePayroll ? (
                <LinkButton
                  size="small"
                  onClick={() =>
                    navigate(`/contact/bank-details/${params.employeeKey}`)
                  }>
                  Manage People
                </LinkButton>
              ) : (
                <LinkButton size="small" onClick={() => setVisible(true)}>
                  Add payslip email
                </LinkButton>
              )
            }
          />
        )}
        <DefaultBody style={{ maxWidth: 1200 }}>
          <Row gutter={[24, 8]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={16}>
              <PersonalSalarySlipTable
                payrollDetails={payrollDetails}
                getAllPayrollDetails={getAllPayrollDetails}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={8}>
              <PersonalDetails employees={dataSource} />
              {payrollDetails?.currentExchangeRate && (
                <Div
                  border={`1px solid ${theme.gray300}`}
                  background={theme.warn}
                  p="12px 17px"
                  mb={24}
                  borderRadius="4px">
                  <B type="b-large-semibold">
                    Current Exchange Rate:{' '}
                    {payrollDetails?.peggedCurrency +
                      ' ' +
                      payrollDetails?.currentExchangeRate}
                  </B>
                </Div>
              )}

              {organizationData?.prorataSettings?.prorata.status &&
                payrollDetails && (
                  <WorkedDaysForm
                    updatedWorkedDays={
                      dataUpdateWorkedDays
                        ? dataUpdateWorkedDays?.workedDays
                        : null
                    }
                    submitWorkedDays={(v) => submitWorkedDays(v)}
                    monthlyWorkingDaysSetting={
                      organizationData.monthlyWorkingDaysSettings
                    }
                    monthlyWorkingDays={organizationData.monthlyWorkingDays}
                    payrollDetails={payrollDetails}
                    variableMonthlyWorkingDays={
                      payrollDetails?.variableWorkingDays
                    }
                    status={payrollDetails?.status}
                    prorataSettings={organizationData?.prorataSettings}
                  />
                )}

              <Attendance employees={dataSource} />

              {expensePayitem && (
                <Noticecard
                  border={`1px solid ${theme.gray300}`}
                  backgroundColor={theme.gray50}
                  display="flex"
                  justifyContent="space-between"
                  verticalAlignment="center">
                  <B type="b-large-semibold">Expenses</B>
                  <B type="b-small">
                    Rs. {numberThousandSeparator(expensePayitem?.amount)}
                  </B>
                </Noticecard>
              )}
              {organizationData?.prorataSettings?.prorata.status && (
                <SpecialNote
                  updatedWorkedDays={dataUpdateWorkedDays}
                  employees={dataSource}
                  payrollEndDate={payrollDetails?.payrollEndDate}
                  payrollStartDate={payrollDetails?.payrollStartDate}
                />
              )}
              <CollapseItems
                visibleBankDetailModal={visible}
                onCancel={() => setVisible(false)}
              />
            </Col>
          </Row>
          <Div mt="70px">
            <FooterComponent
              leftChildren={
                <SecondaryButton
                  htmlType="button"
                  onClick={() =>
                    navigate(`/Payroll-view/${params.payrollKey}`, {
                      state:
                        params.salaryInformationViewType ===
                        SalaryInformationView.CONFIRM_EMPLOYEE
                          ? SalaryInformationView.CONFIRM_EMPLOYEE
                          : null,
                    })
                  }>
                  Back
                </SecondaryButton>
              }
              rightChildren={
                isCompletePayroll ? (
                  <>
                    <SecondaryButton onClick={() => downloadPaySlip()}>
                      Download Payslip
                    </SecondaryButton>
                    <PrimaryButton
                      ml={16}
                      onClick={() => {
                        if (userPayrollDetail) {
                          postSendPayslip(userPayrollDetail.id);
                        }
                      }}>
                      Send Payslip Now
                    </PrimaryButton>
                  </>
                ) : null
              }
            />
          </Div>
        </DefaultBody>
      </Spin>
    </>
  );
};

export default PaySlipPage;
