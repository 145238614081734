import { cloneDeep } from 'lodash';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { SIDE_MENU_ITEMS, SUPER_ADMIN_SIDE_MENU } from '../configs';
import { useAttendanceState } from '../context/AttendanceContext';
import { RootState } from '../store/reducer';
import { MenuItemType } from '../types';
import { getPathWithOrgId } from './getPathWithOrgId';
import { isAllowedAnyOf, isManager } from './permissionUtil';
import { isCurrentAuthSuperAdmin } from './storageUtil';

export function useGetMenuItems() {
  const [menuItems, setMenuItems] = React.useState<MenuItemType[]>([]);
  const { isTimeAttendance } = useAttendanceState();
  const currentAuthPayload = useSelector(
    (state: RootState) => state.auth.currentAuthPayload,
  );

  React.useEffect(() => {
    if (isCurrentAuthSuperAdmin()) {
      setMenuItems(SUPER_ADMIN_SIDE_MENU);
    } else {
      const newItems = cloneDeep(SIDE_MENU_ITEMS).filter((item) => {
        if (item.displayName === 'Time Attendance') {
          return isTimeAttendance;
        }

        if (item.permissions && item.hasException) {
          item.path = getPathWithOrgId(item.path);
          return isManager() || isAllowedAnyOf(item.permissions);
        }
        if (!item.permissions || isAllowedAnyOf(item.permissions)) {
          item.path = getPathWithOrgId(item.path);
          if (!item.subMenus) {
            return true;
          } else {
            const subMenus = [...item.subMenus];
            item.subMenus = subMenus.filter((sub) =>
              sub.permissions ? isAllowedAnyOf(sub.permissions) : true,
            );

            if (item.subMenus.length) {
              item.subMenus = item.subMenus.map((subItem) => {
                subItem.path = getPathWithOrgId(subItem.path);
                return subItem;
              });
              return true;
            }
          }
        }
        return false;
      });
      setMenuItems(newItems);
    }
  }, [currentAuthPayload, isTimeAttendance]);

  return menuItems;
}
