import { message } from 'antd';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQuery } from 'react-query';

import { MyPayslipI } from '../containers/account-details/pages/account-pay-details/AccountPayslips';
import { AllPeopleItemsI, AllPeopleLogTrailI } from '../types/logTrail.tyoes';
import {
  AddContactEmailI,
  AddEmployeeDetailsI,
  AddEmployeeI,
  AddExternalAccountantI,
  AddPendingEmployeeI,
  AddPendingEmployeeResponseI,
  AddUserPayItemsSummary,
  EmployeeEmailOnboardingFormI,
  EmployeeEmailOnboardingSendEmailI,
  EmployeeWithSalaryI,
  IndividualPeopleI,
  PayslipI,
  PendingUserI,
  PeopleI,
  PeoplePayDetailI,
  SendNotificationI,
  SummaryDataI,
  UpdateUserSettingsI,
  UserPayDetailI,
  UserPayItemSummaryI,
} from '../types/people.types';
import { getApiError } from '../util/getApiError';
import { NotificationTypeEnum } from '../types/notification.types';

export const useGetPeople = () =>
  useMutation<IndividualPeopleI, AxiosError, string>(async (userId) => {
    const response: AxiosResponse<IndividualPeopleI> = await axios.get(
      `/users/${userId}`,
    );
    return response.data;
  });

export const useGetEmployeesWithSalary = () => {
  return useQuery<EmployeeWithSalaryI[], AxiosError>(
    'employeesWithSalaries',
    async () => {
      const response: AxiosResponse<EmployeeWithSalaryI[]> = await axios.get(
        `/users/employee-with-salaries`,
      );
      return response.data || [];
    },
    {
      onError: () => {
        message.error('Something went wrong');
      },
    },
  );
};

export const usePostNewEmployee = () => {
  return useMutation<SummaryDataI, AxiosError, AddEmployeeI>(
    async (data: AddEmployeeI) => {
      const response: AxiosResponse<SummaryDataI> = await axios.post('/users', {
        ...data,
        isLoginAllowed: !!data.isLoginAllowed,
      });
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
      onSuccess: () => {
        message.success('New employee created successfully');
      },
    },
  );
};

export const useGetAddEmployeeSalarySummary = () =>
  useMutation<UserPayItemSummaryI, AxiosError, AddUserPayItemsSummary>(
    async (data) => {
      const response = await axios.put(
        `/users/add-employee-salary-summary`,
        data,
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
    },
  );

export const useGetAddEmployeeSummary = () =>
  useMutation<SummaryDataI, AxiosError, AddEmployeeI>(
    async (data: AddEmployeeI) => {
      const response: AxiosResponse<SummaryDataI> = await axios.post(
        `/users/add-employee-summary`,
        { ...data, isLoginAllowed: !!data.isLoginAllowed },
      );
      return response.data;
    },
  );

export const useGetAllUserLogs = () =>
  useMutation<AllPeopleLogTrailI[], AxiosError>(
    'all-user-logs',
    async () => {
      const response: AxiosResponse<AllPeopleLogTrailI[]> = await axios.get(
        '/users/all-user-logs',
      );
      return response.data;
    },

    {
      onError: (error) => {
        message.error(getApiError(error));
      },
    },
  );

export const useGetAllPeople = () =>
  useMutation<PeopleI[], AxiosError>(
    'get-all-people',
    async () => {
      const limit = 10000;
      const response: AxiosResponse<AllPeopleItemsI> = await axios.get(
        '/users/organization',
        {
          params: {
            limit: limit,
            offset: 1,
            orderBy: 'DESC',
          },
        },
      );
      return response.data.items;
    },

    {
      onError: (error) => {
        message.error(getApiError(error));
      },
    },
  );

export const useAddExternalAccountant = () =>
  useMutation<AxiosResponse, AxiosError, AddExternalAccountantI>(
    async (data: AddExternalAccountantI) => {
      const response: AxiosResponse = await axios.post(
        '/users/addExternalAccountant',
        data,
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
      onSuccess: () => {
        message.success('Guest admin onboarded successfully');
      },
    },
  );

export const useDeletePeoplePayDetails = () => {
  return useMutation<string, AxiosError, number>(
    async (payDetailId: number) => {
      const response: AxiosResponse<string> = await axios.delete(
        `/users/userPayItems/${payDetailId}`,
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
      onSuccess: () => {
        message.success('Successfully deleted the Remuneration..');
      },
    },
  );
};

export const useUpdateUserPayDetails = () => {
  return useMutation<string, AxiosError, PeoplePayDetailI>(
    async ({ id, data }) => {
      const response: AxiosResponse<string> = await axios.put(
        `/users/userPayItems`,
        {
          ...data,
          userId: Number(data.userId),
          isEpf: data.isEpf || false,
          isTaxable: data.isTaxable || false,
          id: id,
        },
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
      onSuccess: () => {
        message.success('Remuneration updated successfully!');
      },
    },
  );
};

export const useAddPayDetails = () =>
  useMutation<AxiosResponse, AxiosError, UserPayDetailI>(
    async (data) => {
      const response: AxiosResponse = await axios.post('/users/userPayItems', {
        ...data,
        userId: Number(data.userId),
        isEpf: data.isEpf || false,
        isTaxable: data.isTaxable || false,
      });
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
      onSuccess: () => {
        message.success('New Remuneration added successfully!');
      },
    },
  );

export const useGetPayslips = () =>
  useMutation<PayslipI, AxiosError, number>(
    async (userId: number) => {
      const response: AxiosResponse<PayslipI> = await axios.get(
        `/users/payslip/${userId}`,
      );
      return response.data;
    },

    {
      onError: (error) => {
        message.error(getApiError(error));
      },
    },
  );

export const useGetMyPayslips = () =>
  useMutation<MyPayslipI[], AxiosError>(
    'my-payslips',
    async () => {
      const response: AxiosResponse<MyPayslipI[]> = await axios.get(
        '/users/my-payslips',
      );
      return response.data;
    },

    {
      onError: (error) => {
        message.error(getApiError(error));
      },
    },
  );

export const useUpdatePeopleSetting = () =>
  useMutation<string, AxiosError, UpdateUserSettingsI>(
    async (data) => {
      const updateData = {
        ...data,
      };
      const response: AxiosResponse = await axios.put(
        'users/updateUserSettings',
        updateData,
      );
      return await response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
      onSuccess: (messageText) => {
        messageText === 'success'
          ? message.success('User settings updated successfully')
          : message.success(messageText);
      },
    },
  );

export const useDeleteUser = () =>
  useMutation<string, AxiosError, number>(
    async (id) => {
      const response: AxiosResponse = await axios.delete(`users/${id}`);

      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
      onSuccess: () => {
        message.success('User deleted successfully!');
      },
    },
  );

export const useIsDeletableUser = () =>
  useMutation<boolean, AxiosError, number>(
    async (id) => {
      const response: AxiosResponse = await axios.get(
        `users/isUserDeletable/${id}`,
      );

      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
    },
  );

export const useCreatePendingUser = () => {
  return useMutation<
    AddPendingEmployeeResponseI,
    AxiosError,
    AddPendingEmployeeI
  >(
    async (data: AddPendingEmployeeI) => {
      const response: AxiosResponse<AddPendingEmployeeResponseI> =
        await axios.post('/users/createPendinguser', {
          ...data,
        });
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
      onSuccess: () => {
        message.success('New pending employee created successfully');
      },
    },
  );
};

export const useGetPendingUsers = () => {
  return useMutation<PendingUserI[], AxiosError>(
    'get-pending-users',
    async () => {
      const response: AxiosResponse<PendingUserI[]> = await axios.get(
        '/users/getPendingUsers',
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
    },
  );
};

export const useSendEmailOnboardUser = () => {
  return useMutation<string, AxiosError, AddContactEmailI>(
    async (data: AddContactEmailI) => {
      const response: AxiosResponse<string> = await axios.post(
        '/email/onboard-employee-email',
        { ...data },
      );
      return response.data;
    },
  );
};

export const useSubmitForm = () => {
  return useMutation<string, AxiosError, EmployeeEmailOnboardingFormI>(
    async (data: EmployeeEmailOnboardingFormI) => {
      const response: AxiosResponse<string> = await axios.post(
        '/users/submitPendinguserForm',
        {
          ...data,
        },
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
    },
  );
};

export const useSendNotification = () => {
  return useMutation<string, AxiosError, SendNotificationI>(
    async (data: SendNotificationI) => {
      const response: AxiosResponse<string> = await axios.post(
        '/notification',
        {
          ...data,
        },
      );
      return response.data;
    },
  );
};

export const sendEmailCopyToUser = () => {
  return useMutation<string, AxiosError, EmployeeEmailOnboardingSendEmailI>(
    async (data: EmployeeEmailOnboardingSendEmailI) => {
      const formData = new FormData();

      for (const [key, value] of Object.entries(data)) {
        if (key !== 'files') {
          formData.append(key, value);
        }
      }

      const files = data.files;
      files.forEach((file) => {
        formData.append('files', file);
      });

      const response: AxiosResponse<string> = await axios.post(
        '/users/sendEmailCopyToUser',
        formData,
        {
          // This header is used here because we are sending multiple files with
          // other normal formData
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
    },
  );
};
