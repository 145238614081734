import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { B, Div } from '../../../../components';
import {
  SalaryContainer,
  SalaryItem,
} from '../../../../components/SalarySlip/SalaryItem';
import { RootState } from '../../../../store/reducer';
import theme from '../../../../theme';
import { PayrollItemTypeEnum } from '../../../../types/payroll.types';
import { numberThousandSeparator } from '../../../../util/utils';
import { getSalaryItemsByType } from '../../../payroll/pages/payslip/utils/getSalaryItemsByType';
import { RegulatoryComplianceDataItem } from '../../../people/pages/addEmployeeManually/shared/RegulatoryComplianceDataItem';

const FormBody = styled.div`
  padding: 24px 32px 8px;
  backgroundColor= ${theme.white};
  @media only screen and (max-width: 1200px) {
    padding: 32px 24px;
  }

  @media only screen and (max-width: 768px) {
    padding: 0px;
  }
`;

const PayAmount = styled.div`
  margin-left: auto;
  display: flex;
  padding-right: 30px;
`;
const NetPay = styled.div`
  margin-left: auto;
  display: flex;
  padding-right: 32px;
`;
const SalaryInfo: FC = () => {
  const me = useSelector((state: RootState) => state.people.me);
  const itemByTypes = getSalaryItemsByType(
    me?.userPayItems ? me.userPayItems : [],
  );

  const RegulatoryComplianceData = me?.calculation
    ? Object.entries(me?.calculation).map(([key, v]) => ({
        payTitle: key,
        amount: v,
        isEpf: false,
        isTaxable: false,
      }))
    : [];

  const itemByBasic = itemByTypes[PayrollItemTypeEnum.BASIC];
  const itemByFixedAllowances =
    itemByTypes[PayrollItemTypeEnum.FIXED_ALLOWANCE];
  const itemByVariableAllowances =
    itemByTypes[PayrollItemTypeEnum.VARIABLE_ALLOWANCE];
  const itemByLumpSum = itemByTypes[PayrollItemTypeEnum.LUMP_SUM];
  const itemByDeductions = itemByTypes[PayrollItemTypeEnum.DEDUCTION];
  return (
    <>
      <FormBody>
        <B type="b-large-semibold" pb="22px">
          Earnings
        </B>
        <SalaryContainer>
          <SalaryItem
            status="COMPLETED"
            title="Basic Salary"
            salaryItems={itemByBasic && itemByBasic.length ? itemByBasic : []}
          />
        </SalaryContainer>
        <SalaryContainer>
          <SalaryItem
            status="COMPLETED"
            title="Fixed Allowances"
            salaryItems={
              itemByFixedAllowances && itemByFixedAllowances.length
                ? itemByFixedAllowances
                : []
            }
          />
        </SalaryContainer>
        <SalaryContainer>
          <SalaryItem
            status="COMPLETED"
            title="Variable Allowances"
            salaryItems={
              itemByVariableAllowances && itemByVariableAllowances.length
                ? itemByVariableAllowances
                : []
            }
          />
        </SalaryContainer>
        {itemByLumpSum.length > 0 && (
          <SalaryContainer>
            <SalaryItem
              status="COMPLETED"
              title="Lump Sum Payments"
              salaryItems={
                itemByLumpSum && itemByLumpSum.length ? itemByLumpSum : []
              }
            />
          </SalaryContainer>
        )}
        <SalaryContainer>
          <SalaryItem
            status="COMPLETED"
            title="Deductions"
            salaryItems={
              itemByDeductions && itemByDeductions.length
                ? itemByDeductions
                : []
            }
          />
        </SalaryContainer>
        {me?.userPayItems && me?.userPayItems.length > 0 && (
          <>
            <SalaryContainer>
              <Div
                borderBottom={`1px solid ${theme.gray300}`}
                py="7px"
                background={theme.gray100}
                display="flex"
                borderRadius="2px"
                mb="22px">
                <NetPay>
                  <B type="b-small" color={theme.gray600} pt="2px">
                    Net Pay
                  </B>
                  &nbsp;
                  <B type="b-large-semibold" color={theme.black}>
                    {numberThousandSeparator(me?.calculation?.netSalary || 0)}
                  </B>
                </NetPay>
              </Div>
            </SalaryContainer>
            <br />
            <Div>
              <B type="b-large-semibold" pb="22px">
                Regulatory Compliance
              </B>
              <RegulatoryComplianceDataItem
                salaryItems={RegulatoryComplianceData.filter(
                  (item) => item.payTitle === 'employeeEPF',
                )}
                title="Employee"
              />
              <br />
              <RegulatoryComplianceDataItem
                salaryItems={[
                  ...RegulatoryComplianceData.filter(
                    (item) =>
                      item.payTitle === 'employerEPF' ||
                      item.payTitle === 'employerETF',
                  ),
                ]}
                title="Employer"
              />
              <br />
              {me?.calculation?.apitTable1Amount != 0 && (
                <RegulatoryComplianceDataItem
                  salaryItems={[
                    ...RegulatoryComplianceData.filter(
                      (item) => item.payTitle === 'apitTable1Amount',
                    ),
                  ]}
                  title="APIT"
                />
              )}
            </Div>
            <br />
            <Div py={10} display="flex">
              <B type="b-large-semibold" color={theme.black}>
                Cost to company
              </B>
              <PayAmount>
                <B type="b-large-semibold" color={theme.black}>
                  {numberThousandSeparator(me?.calculation?.costToCompany || 0)}
                </B>
              </PayAmount>
            </Div>
          </>
        )}
      </FormBody>
    </>
  );
};

export default SalaryInfo;
