import React, { FC } from 'react';
import { useParams } from 'react-router';
import { B, Div, NameTag } from '../../../../components';
import theme from '../../../../theme';
import { EmployeePaySlipSummary } from '../../../../types/payslipPageTypes';
import { getEmployeeSelectorOption } from './utils/getEmployeeSelectorOption';

const TagStyle = `
  display: inline;
  border-radius: 4px;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  padding: 1px 12px 22px;
  margin-left: 10px;
`;
interface Props {
  employees: EmployeePaySlipSummary[];
}

type RouteParams = {
  employeeKey: string;
};

export const PersonalDetails: FC<Props> = ({ employees }) => {
  const params = useParams<RouteParams>();
  const options = getEmployeeSelectorOption(employees);
  const emp =
    options.find((option) => option.id == Number(params.employeeKey)) || null;
  if (!emp) {
    return null;
  }
  return (
    <>
      <Div
        p="12px 17px"
        mb={24}
        background={theme.gray50}
        border={`1px solid ${theme.gray300}`}
        borderRadius={4}>
        <Div display="flex">
          <B type="b-large-semibold">
            {emp?.label} ({emp?.employeeNumber})
          </B>
          {!!emp.isSecondary && (
            <NameTag
              textColor={theme.blue500}
              color={theme.lightBlue50}
              styleCSS={TagStyle}>
              Secondary
            </NameTag>
          )}
        </Div>
        <Div display="flex">
          <B type="b-small" color={theme.gray600}>
            Designation :
          </B>
          <B type="b-small" color={theme.black} pl="8px">
            {emp?.designation}
          </B>
        </Div>
        {emp.isEmployeePaidInCash && (
          <Div display="flex">
            <B type="b-small" color={theme.gray600}>
              Payment Mode :
            </B>
            <B type="b-small" color={theme.black} pl="8px">
              Cash
            </B>
          </Div>
        )}
      </Div>
    </>
  );
};
