//import 'antd/dist/antd.css'; // TODO: do we really need this
import React, { FC } from 'react';
import { Col, Divider, Row } from 'antd';
import styled from '@emotion/styled';
import { useWindowSize } from '../../../../../util/screenSize';
import { B } from '../../../../../components';
import { numberThousandSeparator } from '../../../../../util/utils';
import { getPayItemSum } from '../../../../payroll/utils/getPayItemSum';
import { SalaryItemsByTypeI } from '../../../../payroll/pages/payslip/utils/getSalaryItemsByType';
import { uniqueId } from 'lodash';
import { camelCaseToNormalText } from '../../../../../util/camelCaseToNormal.util';

export interface RegulatoryComplianceDataI {
  amount: number;
  payTitle: string | null;
}
interface Props {
  salaryItems: RegulatoryComplianceDataI[];
  title: string;
}
const DividerComp = styled(Divider)`
  margin: 16px 0px 0px;
`;
const getPayTitle = (payTitle: string) => {
  switch (payTitle) {
    case 'employeeEPF':
      return 'EPF 8%';
    case 'employerEPF':
      return 'EPF 12%';
    case 'employerETF':
      return 'ETF 3%';
    default:
      return camelCaseToNormalText(payTitle);
  }
};
export const RegulatoryComplianceDataItem: FC<Props> = ({
  salaryItems,
  title,
}) => {
  const { isMobile, isTablet } = useWindowSize();
  return (
    <>
      <Row>
        <Col span={6}>
          <B type="b-small-semibold">{title}</B>
        </Col>
        <Col span={18}>
          <Row justify="space-between" align="bottom">
            <Col span={14}>
              {salaryItems.map((item) => {
                return (
                  <Row key={uniqueId()}>
                    <Col span={12}>
                      <B type="b-small">
                        {item.payTitle && getPayTitle(item.payTitle)}
                      </B>
                    </Col>
                    <Col span={12}>
                      <Row justify="end">
                        <B type="b-small">
                          {title != 'Employee' &&
                            numberThousandSeparator(item.amount)}
                        </B>
                      </Row>
                    </Col>
                  </Row>
                );
              })}
            </Col>
            {title != 'Employee' ? (
              <Col span={10}>
                <Row justify="end">
                  <B type="b-small" pr={isMobile && !isTablet ? '0px' : '32px'}>
                    {getPayItemSum(salaryItems as Partial<SalaryItemsByTypeI>)}
                  </B>
                </Row>
              </Col>
            ) : (
              salaryItems.map((item) => {
                return (
                  <Col span={10}>
                    <Row justify="end">
                      <B
                        type="b-small"
                        pr={isMobile && !isTablet ? '0px' : '32px'}>
                        {numberThousandSeparator(item.amount)}
                      </B>
                    </Row>
                  </Col>
                );
              })
            )}
          </Row>
        </Col>
      </Row>
      <DividerComp />
    </>
  );
};
