import { message } from 'antd';
import axios from 'axios';

import {
  BankBranchI,
  PayDetailI,
  PayrollItemI,
} from '../../types/payroll.types';
import {
  BankDetailI,
  BankDetailIForm,
  IndividualPeopleI,
} from '../../types/people.types';
import showApiError from '../../util/showApiError';
import { OrganizationBodyI } from '../../types/organization.types';
import { BankI } from '../../types/bank.types';
import { DataImportUserPayLoad } from './dataImport';

const PAYROLL_DETAILS = 'PAYROLL_DETAILS';
const PAYROLL_DETAILS_LOADING = 'PAYROLL_DETAILS_LOADING';
const PAYROLL_SUMMERY = 'PAYROLL_SUMMERY';
const PAYROLL_SUMMERY_LOADING = 'PAYROLL_SUMMERY_LOADING';
const FILE_DOWNLOAD = 'FILE_DOWNLOAD';
const FILE_DOWNLOAD_LOADING = 'FILE_DOWNLOAD_LOADING';
const PAYSLIP_GENERATOR_LOADING = 'PAYSLIP_GENERATOR_LOADING';
const PAYSLIP_GENERATOR = 'PAYSLIP_GENERATOR';
const ERROR_HANDLING = 'ERROR_HANDLING';
const VALIDATE_PAYROLL_DETAILS_LOADING = 'VALIDATE_PAYROLL_DETAILS_LOADING';
const VALIDATE_PAYROLL_DETAILS = 'VALIDATE_PAYROLL_DETAILS';
const VALIDATED_PAYROLL_VIEW_DATA = 'VALIDATED_PAYROLL_VIEW_DATA';
const VALIDATED_PAYROLL_VIEW_DATA_LOADING =
  'VALIDATED_PAYROLL_VIEW_DATA_LOADING';

const EMAIL_SEND_LOADING = 'EMAIL_SEND_LOADING';
const PUBLISH_PAYROLL_SUMMARY = 'PUBLISH_PAYROLL_SUMMARY';
const PUBLISH_PAYROLL_SUMMARY_LOADING = 'PUBLISH_PAYROLL_SUMMARY_LOADING';
const PAYROLL_LOADING = 'PAYROLL_LOADING';
const SINGLE_PAYROLL_DATA = 'SINGLE_PAYROLL_DATA';
const SINGLE_PAYROLL_DATA_LOADING = 'SINGLE_PAYROLL_DATA_LOADING';
const PAYROLL_SCHEDULE_LOAD = 'PAYROLL_SCHEDULE_LOAD';
const UNARCHIVE_LOADING = 'UNARCHIVE_LOADING';
const SELECT_USER_PAYROLL = 'SELECT_USER_PAYROLL';
const PAYSLIP_UPLOAD_LOADING = 'PAYSLIP_UPLOAD_LOADING';

export interface EmployeePayDetailI extends PayDetailI {
  userHistoryId: string;
}

export interface payrollI {
  createdAt: string | null;
  id: string | null;
  name: string | null;
  organization: OrganizationBodyI;
  organizationId: string | null;
  payrollEndDate: Date;
  payrollStartDate: Date;
  status: string | null;
  updateAt: string | null;
}

export interface LeaveInformationI {
  averageLeaves: number;
  otHours: number;
  otRate: number;
  sundayOtHours: number;
  sundayOtRate: number;
  approvedLeaves: number;
  openLeaveBalance: number;
  noPayDays: number;
  noPayRate: number;
  [key: string]: number;
}

export interface CustomPayslipField {
  key: string;
  value: string;
}
export interface EmpPayrollI extends BankDetailI {
  companyRef: string | null;
  costToCompany: number;
  createdAt: string | null;
  id: number;
  netSalary: number;
  payrollId: number;
  deduction: number;
  payrollItems: PayrollItemI[];
  payslipUrl: string;
  remark: string | null;
  updateAt: string | null;
  user: IndividualPeopleI;
  userId: number;
  isSelected: boolean;
  leaveInformation: LeaveInformationI;
  payslipFields?: CustomPayslipField[];
  bankBranch: BankBranchI | null;
  bank: BankI | null;
  isNewInDraft: boolean;
  primaryAccountAmount: number | null;
  deliveryStatus?: MailDeliveryStatusEnum | null;
  deliveryDate?: string | null;
  payroll?: SingleUserPayroll | null;
  isProRata: boolean;
  proRataDays: number | null;
  totalCompensation: number;
  attendance?: number;
  workedDays?: number;
}

export interface ValidatePayrollI extends SalaryInformationI {
  validationStatus: boolean;
  validationMessage: [];
}
export interface SalaryInformationI {
  empPayroll: EmpPayrollI[];
  id: number;
  name: string | null;
  organizationId: number;
  payrollEndDate: string | null;
  payrollStartDate: string | null;
  scheduleDate: string | null;
  status: 'DRAFTED' | 'COMPLETED';
  transferFileUrl: null;
  month?: string;
  currentExchangeRate?: number | null;
  peggedCurrency?: string | null;
  peggedBasedCurrency?: string | null;
  payrunType: string;
  variableWorkingDays?: number;
}

export interface PayrollViewUserPayLoad extends DataImportUserPayLoad {
  id: string;
  costToCompany: number | string;
  netSalary: number | string;
  deduction: number | string;
  payrollId: number | string;
  isSelected: boolean;
}
export interface PayrollViewDataI {
  defaultRemark: string | null;
  empPayroll: PayrollViewUserPayLoad[];
  id: number;
  name: string | null;
  organizationId: number;
  payrunType: string | null;
  month: string | null;
  year: number | null;
  payrollEndDate: string | null;
  payrollStartDate: string | null;
  scheduleDate: string | null;
  status: 'DRAFTED' | 'COMPLETED';
  transferFileUrl: null;
  currentExchangeRate?: number | null;
  peggedCurrency?: string | null;
  isEdited?: boolean | null;
}

export interface PayRollSummaryI {
  nextPayroll: string | null;
  lastPayrollAmount: number;
  previousPayroll: PreviousPayrollI[];
}
export interface PreviousPayrollI {
  id: string;
  createdAt: string | null;
  name: string | null;
  payrunType: string | null;
  month: string | null;
  year: number | null;
  payrollStartDate: string | null;
  payrollEndDate: string | null;
  status: 'DRAFTED' | 'COMPLETED';
  costToCompany: number | null;
  transferFileUrl: string;
  bulkPayslipStatus?: BulkPaySlipDownloadEnum;
  bulkPayslipUrl?: string;
  isArchived: boolean;
  currentExchangeRate?: number | null;
  peggedCurrency?: string | null;
  isEdited: boolean;
}
export interface PayrunI {
  name: string | null;
  payrunType: string | null;
  month: string | null;
  year: number | null;
  payrollStartDate: string | null;
  payrollEndDate: string | null;
  defaultRemark: string | null;
  payrollId?: string | null;
  currentExchangeRate?: number | null;
  peggedCurrency?: string | null;
  peggedBasedCurrency?: string | null;
  variableWorkingDays?: number | null;
  attendanceId?: string | null;
}

export interface PayrunFormI {
  name: string | null;
  payrunType: string | null;
  month: string | null;
  year: number | null;
  datePeriod: [] | null;
  defaultRemark: string | null;
  payrollId: string | null;
  id?: string | null;
  currentExchangeRate?: number | null;
  variableWorkingDays?: number | null;
}

export interface PublishPayrollI {
  date: string | null;
}

export interface updatePayrollItemI {
  itemId: string;
  amount: number;
}
export enum BulkPaySlipDownloadEnum {
  GENERATED = 'GENERATED',
  IN_PROGRESS = 'IN_PROGRESS',
  ERROR = 'ERROR',
  PENDING = 'PENDING',
}
//Need to keep enum value in lowercase since webhook uses those values in lowercase
export enum MailDeliveryStatusEnum {
  PENDING = 'pending',
  DELIVERED = 'delivered',
  FAILED = 'failed',
  UNSUBSCRIBED = 'unsubscribed',
  NO_DATA = 'no_data',
}

export interface FilePayslipI {
  id?: number;
  name: string | null;
  transferFileUrl: string;
  payrollStartDate: string | null;
  payrollEndDate: string | null;
  bulkPayslipStatus?: BulkPaySlipDownloadEnum | null;
  bulkPayslipUrl?: string | null;
  completion: number;
  createdAt: string | null;
  dateOfCrediting: null;
  defaultRemark: string | null;
  isArchived: boolean;
  organizationId: number;
  payslipsSentDate: string | null;
  scheduleDate: string | null;
  status: 'COMPLETED';
  updateAt: string | null;
  valueDate: null;
  payrunType: string | null;
}

export type PaySlipEmpPayroll = Pick<
  EmpPayrollI,
  'id' | 'deliveryDate' | 'deliveryStatus' | 'user' | 'payroll'
>;
export interface PayslipDeliveryStatusI {
  isComplete: boolean;
  empPayrolls: PaySlipEmpPayroll[];
}

export interface LeaveBalanceInformation {
  empPayrollId: number | undefined;
  payrollId: number | undefined;
  openLeaveBalance?: number;
  averageLeaves?: number;
  otHours?: number;
  otRate?: number;
  approvedLeaves?: number;
  noPayDays: number;
}

export interface SingleUserPayroll extends FilePayslipI {
  organizationId: number;
  scheduleDate: string | null;
  payslipsSentDate: string | null;
  defaultRemark: string;
  createdAt: string;
  updateAt: string;
  isPayslipsUploaded: boolean;
}

export interface PayslipI {
  payrollId: string;
  dateOfCrediting?: string;
  valueDate?: string;
}

export interface DeductionGroupI {
  name: string;
  amount: number;
  type: string;
}

export interface PublishSummaryPayrollI {
  basic: number;
  fixedAllowance: number;
  variableAllowance: number;
  netSalary: number;
  costToCompany: number;
  employeeEpf: number;
  employerEpf: number;
  etf: number;
  incomeTax: number;
  deductionsGroup: DeductionGroupI[];
  allowanceGroup: DeductionGroupI[];
  payrollStartDate: string;
  payrollEndDate: string;
  noOfEmployees: number;
  APIT: number;
  lumpSumTotal: number;
}

interface InitialStateI {
  allPayrollDetailsLoading: boolean;
  allPayrollDetails: SalaryInformationI | null;
  organization: OrganizationBodyI[];
  organizationLoading: boolean;
  payrollSummery: PayRollSummaryI | null;
  payrollSummeryLoading: boolean;
  payrollData: PayrunFormI[];
  payrollLoading: boolean;
  fileTransfer: FilePayslipI | null;
  fileTransferLoading: boolean;
  payslipStatus: any;
  payslipStatusLoading: boolean;
  errorHandling: boolean;
  validatePayrollData: ValidatePayrollI | null;
  validatePayrollDataLoading: boolean;
  validatePayrollViewData: PayrollViewDataI | null;
  validatePayrollViewDataLoading: boolean;
  emailSendLoading: boolean;
  payslipUploadLoading: boolean;
  publishPayrollSummaryDetails: PublishSummaryPayrollI | null;
  publishPayrollSummaryLoading: boolean;
  singlePayrollData: SingleUserPayroll | null;
  singlePayrollDataLoading: boolean;
  payrollScheduleLoad: boolean;
  unarchiveLoading: boolean;
  selectUserPayroll: string | null;
}
const initialState: InitialStateI = {
  allPayrollDetailsLoading: false,
  allPayrollDetails: null,
  payrollSummery: null,
  payrollSummeryLoading: false,
  organization: [],
  organizationLoading: false,
  payrollData: [],
  payrollLoading: false,
  fileTransfer: null,
  fileTransferLoading: false,
  payslipStatus: null,
  payslipStatusLoading: false,
  errorHandling: false,
  validatePayrollData: null,
  validatePayrollDataLoading: false,
  validatePayrollViewData: null,
  validatePayrollViewDataLoading: false,
  emailSendLoading: false,
  publishPayrollSummaryDetails: null,
  publishPayrollSummaryLoading: false,
  singlePayrollData: null,
  singlePayrollDataLoading: false,
  payrollScheduleLoad: false,
  unarchiveLoading: false,
  selectUserPayroll: null,
  payslipUploadLoading: false,
};

export default function reducer(
  state: InitialStateI = initialState,
  action: any,
): InitialStateI {
  switch (action.type) {
    case PAYROLL_DETAILS:
      return {
        ...state,
        allPayrollDetails: action.payload,
      };
    case PAYROLL_DETAILS_LOADING:
      return {
        ...state,
        allPayrollDetailsLoading: action.payload,
      };
    case PAYROLL_SUMMERY:
      return {
        ...state,
        payrollSummery: action.payload,
      };
    case PAYROLL_SUMMERY_LOADING:
      return {
        ...state,
        payrollSummeryLoading: action.payload,
      };
    case FILE_DOWNLOAD:
      return {
        ...state,
        fileTransfer: action.payload,
      };
    case FILE_DOWNLOAD_LOADING:
      return {
        ...state,
        fileTransferLoading: action.payload,
      };
    case PAYSLIP_GENERATOR:
      return {
        ...state,
        payslipStatus: action.payload,
      };
    case PAYSLIP_GENERATOR_LOADING:
      return {
        ...state,
        payslipStatusLoading: action.payload,
      };
    case ERROR_HANDLING:
      return {
        ...state,
        errorHandling: action.payload,
      };
    case VALIDATE_PAYROLL_DETAILS_LOADING:
      return {
        ...state,
        validatePayrollDataLoading: action.payload,
      };
    case VALIDATE_PAYROLL_DETAILS:
      return {
        ...state,
        validatePayrollData: action.payload,
      };
    case VALIDATED_PAYROLL_VIEW_DATA_LOADING:
      return {
        ...state,
        validatePayrollViewDataLoading: action.payload,
      };
    case VALIDATED_PAYROLL_VIEW_DATA:
      return {
        ...state,
        validatePayrollViewData: action.payload,
      };
    case EMAIL_SEND_LOADING:
      return {
        ...state,
        emailSendLoading: action.payload,
      };
    case PAYSLIP_UPLOAD_LOADING:
      return {
        ...state,
        payslipUploadLoading: action.payload,
      };
    case PUBLISH_PAYROLL_SUMMARY:
      return {
        ...state,
        publishPayrollSummaryDetails: action.payload,
      };
    case PUBLISH_PAYROLL_SUMMARY_LOADING:
      return {
        ...state,
        publishPayrollSummaryLoading: action.payload,
      };
    case SINGLE_PAYROLL_DATA:
      return {
        ...state,
        singlePayrollData: action.payload,
      };
    case SINGLE_PAYROLL_DATA_LOADING:
      return {
        ...state,
        singlePayrollDataLoading: action.payload,
      };
    case PAYROLL_SCHEDULE_LOAD:
      return {
        ...state,
        payrollScheduleLoad: action.payload,
      };
    case UNARCHIVE_LOADING:
      return {
        ...state,
        unarchiveLoading: action.payload,
      };
    case SELECT_USER_PAYROLL:
      return {
        ...state,
        selectUserPayroll: action.payload,
      };
    default:
      return state;
  }
}

export function createPayDetails(data: EmployeePayDetailI, userId: string) {
  return async () => {
    try {
      await axios.post('/payroll/userHistoryPayDetail', {
        userId: userId,
        ...data,
        id: String(data.id),
        isEpf: data.isEpf || false,
        isTaxable: data.isTaxable || false,
        userHistoryId: data.userHistoryId,
      });
      message.success('New Remuneration added successfully!');
    } catch (error: any) {
      showApiError(error);
    }
  };
}

export function getAllPayrollDetails(payrollId: string) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: PAYROLL_DETAILS, payload: null });
      dispatch({ type: ERROR_HANDLING, payload: false });
      dispatch({ type: PAYROLL_DETAILS_LOADING, payload: true });
      const res = await axios.get(`/payroll/getPayroll/${payrollId}`);
      dispatch({ type: PAYROLL_DETAILS, payload: res.data });
      dispatch({ type: PAYROLL_DETAILS_LOADING, payload: false });
    } catch (error: any) {
      dispatch({ type: PAYROLL_DETAILS_LOADING, payload: false });
      dispatch({ type: ERROR_HANDLING, payload: true });
    }
  };
}

export function getPayrollSummary() {
  return async (dispatch: any) => {
    try {
      dispatch({ type: ERROR_HANDLING, payload: false });
      dispatch({ type: PAYROLL_SUMMERY_LOADING, payload: true });
      const res = await axios.get('/payroll/getPreviousPayroll');
      dispatch({ type: PAYROLL_SUMMERY, payload: res.data });

      dispatch({ type: PAYROLL_SUMMERY_LOADING, payload: false });
    } catch (error: any) {
      dispatch({ type: PAYROLL_SUMMERY_LOADING, payload: false });
      dispatch({ type: ERROR_HANDLING, payload: true });
    }
  };
}

export function deleteAllPayrollDetails(payrollId: string, msg:string='Successfully deleted draft Payrun') {
  return async (dispatch: any) => {
    try {
      await axios.delete(`payroll/deletePayroll/${payrollId}`);
      await dispatch(getPayrollSummary());
      message.success(msg);
    } catch (error: any) {
      showApiError(error);
    }
  };
}

export function putLeaveInformation(data: LeaveBalanceInformation) {
  return async (dispatch: any) => {
    try {
      await axios.put(`/payroll/leaveInformation`, data);
      message.success('Successfully edited leave information.');
      dispatch(getAllPayrollDetails(String(data.payrollId)));
    } catch (error: any) {
      showApiError(error);
    }
  };
}

export function payrollSchedule(data: PublishPayrollI, payrollId: string) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: PAYROLL_SCHEDULE_LOAD, payload: true });
      await axios.put('/payroll/payroll/schedule', {
        ...data,
        payrollId,
      });
      dispatch({ type: PAYROLL_SCHEDULE_LOAD, payload: false });
      message.success('Successfully Scheduled');
    } catch (error: any) {
      showApiError(error);
      dispatch({ type: PAYROLL_SCHEDULE_LOAD, payload: false });
    }
  };
}

export function clearPayrollStatus() {
  return (dispatch: any) => {
    dispatch({ type: PAYSLIP_GENERATOR, payload: null });
  };
}

export function createPayslips(data: PayslipI, bankType: string) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: PAYSLIP_GENERATOR_LOADING, payload: true });
      const res = await axios.post(
        `/payroll/createPayslip?type=${bankType}`,
        data,
      );
      dispatch({ type: PAYSLIP_GENERATOR, payload: res.data });
      dispatch({ type: PAYSLIP_GENERATOR_LOADING, payload: false });
    } catch (error: any) {
      showApiError(error);
      dispatch({ type: PAYSLIP_GENERATOR_LOADING, payload: false });
    }
  };
}

export function getValidatedPayrollDetails(payrollId: string) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: VALIDATE_PAYROLL_DETAILS_LOADING, payload: true });
      const res = await axios.get(`/payroll/validateData/${payrollId}`);
      dispatch({ type: VALIDATE_PAYROLL_DETAILS, payload: res.data });
      dispatch({ type: VALIDATE_PAYROLL_DETAILS_LOADING, payload: false });
    } catch (error: any) {
      showApiError(error);
      dispatch({ type: VALIDATE_PAYROLL_DETAILS_LOADING, payload: false });
    }
  };
}

export function sendPaySlips(payrollId: string) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: EMAIL_SEND_LOADING, payload: true });
      await axios.post('/payroll/sendPaySlipsNow', { payrollId });
      message.success('Email sent successfully!');
      dispatch({ type: EMAIL_SEND_LOADING, payload: false });
      dispatch(getPayrollData(payrollId));
    } catch (error: any) {
      showApiError(error);
      dispatch({ type: EMAIL_SEND_LOADING, payload: false });
    }
  };
}
export function uploadPayslips(payrollId: string) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: PAYSLIP_UPLOAD_LOADING, payload: true });
      await axios.post('/payroll/uploadPayslips', { payrollId });
      message.success('Payslips uploaded successfully!');
      dispatch({ type: PAYSLIP_UPLOAD_LOADING, payload: false });
      dispatch(getPayrollData(payrollId));
    } catch (error: any) {
      showApiError(error);
      dispatch({ type: PAYSLIP_UPLOAD_LOADING, payload: false });
    }
  };
}

export function getPayrollData(payrollId: string) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: SINGLE_PAYROLL_DATA_LOADING, payload: true });
      const res = await axios.get(`payroll/getPayrollData/${payrollId}`);
      dispatch({ type: SINGLE_PAYROLL_DATA, payload: res.data });
      dispatch({ type: SINGLE_PAYROLL_DATA_LOADING, payload: false });
    } catch (error: any) {
      showApiError(error);
    }
  };
}

export function updatePayrollBankDetails(data: BankDetailIForm, id: string) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: PAYROLL_LOADING, payload: true });

      await axios.put('/payroll/bankDetails', { id, ...data });
      message.success('Bank details submitted successfully');
      dispatch({ type: PAYROLL_LOADING, payload: false });
    } catch (error: any) {
      dispatch({ type: PAYROLL_LOADING, payload: false });
      showApiError(error);
    }
  };
}
export function unarchivePayroll(payrollId: string) {
  return async (dispatch: any) => {
    try {
      dispatch({ type: UNARCHIVE_LOADING, payload: true });
      await axios.post('/payroll/unarchive', { payrollId });
      message.success('Unarchived successfully!');
      dispatch({ type: UNARCHIVE_LOADING, payload: false });
      dispatch(getPayrollSummary());
    } catch (error: any) {
      showApiError(error);
      dispatch({ type: UNARCHIVE_LOADING, payload: false });
    }
  };
}
