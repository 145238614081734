import { EmpPayrollI } from '../store/actions';

export enum SalaryInformationView {
  CONFIRM_EMPLOYEE = 'CONFIRM_EMPLOYEE',
  ALL_EMPLOYEE = 'ALL_EMPLOYEE',
  COMPLETED = 'COMPLETED',
}

export enum PayslipSalaryTitleEnum {
  DEDUCTIONS = 'Deductions',
}

export interface BankBranchI {
  id: string | null;
  bankBankCode: string | null;
  branchCode: string | null;
  branchName: string | null;
}

export enum PayrollItemTypeEnum {
  BASIC = 'BASIC',
  FIXED_ALLOWANCE = 'FIXED_ALLOWANCE',
  VARIABLE_ALLOWANCE = 'VARIABLE_ALLOWANCE',
  DEDUCTION = 'DEDUCTION',
  EMPLOYEE_EPF = 'EMPLOYEE_EPF',
  EMPLOYER_EPF = 'EMPLOYER_EPF',
  ETF = 'ETF',
  APIT = 'APIT',
  APIT_TABLE_1 = 'APIT_TABLE_1',
  APIT_TABLE_2 = 'APIT_TABLE_2',
  APIT_TABLE_2_MID = 'APIT_TABLE_2_MID',
  APIT_TABLE_5 = 'APIT_TABLE_5',
  APIT_TABLE_6 = 'APIT_TABLE_6',
  APIT_TABLE_7 = 'APIT_TABLE_7',
  LUMP_SUM = 'LUMP_SUM',
  NON_CASH_BENEFITS = 'NON_CASH_BENEFITS',
  EXPENSES = 'EXPENSES',
}

export enum otPayItemTypeSelection {
  BASIC = 'OT_BASIC',
  FIXED_ALLOWANCE = 'OT_FIXED_ALLOWANCE',
  VARIABLE_ALLOWANCE = 'OT_VARIABLE_ALLOWANCE',
}

export enum otStatutoryItemSelection {
  APIT = 'OT_APIT',
  EPF = 'OT_EPF',
}

export enum noPayStatutoryItemSelection {
  APIT = 'NOPAY_APIT',
  EPF = 'NOPAY_EPF',
}

export enum PeggedTypeSelectionEnum {
  BASIC = 'PEGGED_BASIC',
  FIXED_ALLOWANCE = 'PEGGED_FIXED_ALLOWANCE',
  VARIABLE_ALLOWANCE = 'PEGGED_VARIABLE_ALLOWANCE',
}

export enum PayDetailSubType {
  DEFAULT = 'DEFAULT',
  NOPAY = 'NOPAY',
}

export enum PayrunType {
  MID_CYCLE_PAYRUN = 'MID_CYCLE_PAYRUN',
  MONTHLY_PAYRUN = 'MONTHLY_PAYRUN',
}

export interface PayrollItemI extends PayDetailI {
  empPayrollId?: number;
}

export interface NextPayrollI {
  APIT: number;
  allowanceGroup: PayrollItemGroupI[];
  costToCompany: number;
  deductionsGroup: PayrollItemGroupI[];
  employeeEpf: number;
  employerEpf: number;
  etf: number;
  fixedAllowance: number;
  lumpSumTotal: number;
  netSalary: number;
  noOfEmployees: number;
  payrollEndDate: string;
  payrollStartDate: string;
  variableAllowance: number;
}
export interface PayrollItemGroupI {
  name: string;
  amount: number;
}

export interface AddDeletePayslipFieldI {
  empPayrollId: number;
  key: string;
  value: string;
}

export interface EditPayslipFieldI {
  empPayrollId: number;
  existingKey: string;
  existingValue: string;
  newKey: string;
  newValue: string;
}

export interface PayslipDeliveryI {
  key: string;
  fullName: string;
  paySlipMail: string;
  payslipsSentDate: string;
  Status: string;
  id: number;
}

export interface SelectUserPayrollI {
  payrollId: string;
  userIds: number[];
}
export type payrollParam = {
  payrollKey: string;
};

export enum TableFilterEnum {
  ALL = 'ALL',
  UPDATED = 'UPDATED',
  ERROR = 'ERROR',
}

export enum PayrollViewTypeEnum {
  LOG_TRAIL_VIEW = 'LOG_TRAIL_VIEW',
  PAYROLL_VIEW = 'PAYROLL_VIEW',
  ERROR = 'ERROR',
  UPDATE = 'UPDATE',
}

export interface PayrollPayDetailI {
  payDetail: PayDetailI;
  payrollId: string;
}
export interface PayDetailI {
  amount: number;
  id?: number;
  isEpf: boolean;
  isTaxable: boolean;
  payTitle: string | null;
  type: PayrollItemTypeEnum;
  subType?: PayDetailSubType;
  noOfdays?: number;
  isSystemCalculated: boolean;
  isFromTimeAttendance?: boolean;
  payrunType: string | null;
}

export interface AddPayItemI extends PayDetailI {
  itemId?: string;
  empPayrollId?: string;
  payrollId: string;
}

export interface DeletePayrollItemI {
  itemIds: number[];
  payrollId: string;
}

export interface PayrollPeriodI {
  month: string;
  year: number;
}
export interface ExistingPayrunsI {
  id: number;
  name: string;
  payrunType: string;
  month: string;
  year: number;
  status: string;
  isArchived: boolean;
}

export interface AddNewPayRunI {
  id: string;
  name: string;
  organizationId: number;
  payrunType: PayrunType;
  month: string;
  year: number;
  payrollStartDate: string;
  payrollEndDate: string;
  scheduleDate: null | string;
  payslipsSentDate: null | string;
  dateOfCrediting: null | string;
  valueDate: null | string;
  defaultRemark: string;
  transferFileUrl: null | string;
  status: 'DRAFTED';
  isArchived: boolean;
  currentExchangeRate: null | string;
  peggedCurrency: null | string;
  peggedBasedCurrency: null | string;
  bulkPayslipStatus: null | string;
  bulkPayslipUrl: null | string;
  completion: number;
  createdAt: string;
  updateAt: string;
  isPayslipsUploaded: boolean;
  payslipsUploadedDate: null | string;
  empPayroll: EmpPayrollI[];
}

export interface UpdateProrataCalculationI {
  empPayrollId: number;
  workedDays: number;
  payrollId: number;
}

export interface TotalRowI {
  empId: string;
  fullName: string;
  grossEarningsForAPIT: number;
  grossEarningsForEPFAndETF: number;
  deduction: number;
  netSalary: number;
  costToCompany: number;
  [key: string]: string | number;
}
