import React, { FC } from 'react';

import { Col, Row } from 'antd';
import { useNavigate } from 'react-router';

import styled from '@emotion/styled';

import { PrimaryButton, SecondaryButton } from '../../../../../components';

const FooterComp = styled.div`
  padding: 16px 32px;
`;
interface Props {
  current: number;
  onClickNext: (num: number) => void;
  onClickBack?: (num: number) => void;
  disabled?: boolean;
  label?: string;
}
const AddEmployeeFooter: FC<Props> = ({
  current,
  onClickNext,
  disabled,
  onClickBack,
  label,
}) => {
  const navigate = useNavigate();
  const handleClickNext = async () => {
    onClickNext(current + 1);
  };

  const handleClickBack = () => {
    if (onClickBack) {
      onClickBack(current - 1);
    }
  };

  return (
    <FooterComp>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          {current > 0 ? (
            <SecondaryButton onClick={handleClickBack}>Back</SecondaryButton>
          ) : (
            <SecondaryButton
              onClick={() => {
                navigate('/contact');
              }}>
              Back
            </SecondaryButton>
          )}
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Row justify="end">
            <PrimaryButton
              htmlType="submit"
              onClick={handleClickNext}
              disabled={disabled}>
              {label ? label : 'Next'}
            </PrimaryButton>
          </Row>
        </Col>
      </Row>
    </FooterComp>
  );
};

export default AddEmployeeFooter;
