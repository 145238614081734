import { Moment } from 'moment';
import { IntegrationStatusEnum } from '.';

export enum IExpenseStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PAID = 'PAID',
  ARCHIVED = 'ARCHIVED',
}

export enum BanksEnum {
  BOC = 'BOC',
  SAMPATH = 'SAMPATH',
  HNB = 'HNB',
  SCB = 'SCB',
  CMB = 'CMB',
  HSBC = 'HSBC',
}

export enum ComBankParams {
  CMBD = 'CMBD',
  CMBO = 'CMBO',
}

export enum ComBankTypeEnum {
  DIGITAL = 'DIGITAL',
  ONLINE = 'ONLINE',
  PAYMASTER = 'PAYMASTER',
}

export enum SampathBankTypeEnum {
  VISHWA = 'VISHWA',
  SCPS = 'SCPS',
}

export const ComBankOptions = [
  {
    label: 'ComBank Digital',
    value: ComBankTypeEnum.DIGITAL,
  },
  {
    label: 'ComBank Paymaster',
    value: ComBankTypeEnum.PAYMASTER,
  },
];

export const SampathBankOptions = [
  {
    label: 'Sampath Bank Vishwa',
    value: SampathBankTypeEnum.VISHWA,
  },
  {
    label: 'Samapth Bank SCPS',
    value: SampathBankTypeEnum.SCPS,
  },
];

export enum ExpenseCreation {
  SYSTEM = 'SYSTEM',
  XERO = 'XERO',
  QUICKBOOKS = 'QUICKBOOKS',
}

export enum ExpenseMeta {
  CATEGORIES = 'CATEGORIES',
  MERCHANTS = 'MERCHANTS',
}

export interface IExpenseUser {
  bankCode: string;
  bankName: string;
  createdAt: string;
  updateAt: string;
}

export interface IExpenseUser {
  id: number;
  employeeNumber: string;
  fullName: string;
  loginEmail: string;
  profilePicture: string | null;
  bank?: IExpenseUser | null;
}
export interface IExpense {
  id: number;
  organizationId: number;
  category: string;
  categoryRef: string;
  creatorId: number;
  userId: number;
  date: string;
  files: string[] | null;
  merchant: string;
  merchantRef: string;
  note: string | null;
  status: IExpenseStatus;
  logs: AuditLogI[] | null;
  amount: number;
  owner: IExpenseUser;
  reference: string;
  creationType: ExpenseCreation;
  createdAt: string;
  updatedAt: string;
}

export interface ExpenseReportI {
  label: string;
  amount: number;
}

export interface AuditLogI {
  actorId: number;
  category: string;
  createdAt: string;
  id: number;
  mapper: AuditLogMapperI;
  message: string;
  organizationId: number;
  referenceId: number;
  type: string;
  updatedAt: string;
}

interface AuditLogMapperI {
  actor?: AuditLogMapperObjectI;
}

interface AuditLogMapperObjectI {
  display: string;
  id: number;
  type: string;
}

export interface ExpenseCreateI {
  date: Moment;
  amount: string;
  categoryRef: string;
  merchantRef: string;
  category: string;
  merchant: string;
  note?: string;
  newUploads?: File[];
  user?: string;
}

export interface UpdateExpenseI {
  id: number;
  data: FormData;
}

export interface UpdateStatusExpenseI {
  id: number;
  status: IExpenseStatus;
}

export interface ExpenseGenerateBulkI {
  type: ComBankParams | BanksEnum;
  remark: string;
  expenseIds: number[];
  markAsPaid: boolean;
  dateOfCrediting?: string;
  valueDate?: string;
}

export interface ExpenseGeneratedBankFilesI {
  createdAt: string;
  reference: number;
  bankName: string;
  dateOfCrediting?: string;
  valueDate?: string;
  transactionReference: string;
  total: number;
  fileUrl: string;
}

export interface IMeta {
  currentPage: number;
  itemCount: number;
  itemsPerPage: number;
  totalItems: number;
  totalPages: number;
}

export interface IBulkFilePayload {
  items: ExpenseGeneratedBankFilesI[];
  meta: IMeta;
}

export interface ExpenseMetaData {
  value: string;
  label: string;
  parentRef?: string;
  subs?: ExpenseMetaData[] | undefined;
  createdDate: string;
  updatedDate: string;
}

export interface SuppliersList {
  label: string;
  value: number | string;
  subs?: SuppliersList[] | undefined;
  parentRef?: string;
}

export interface IntegrationMetaList {
  type: ExpenseCreation;
  metaData: (SuppliersList | ExpenseMetaData)[];
  status?: IntegrationStatusEnum;
}
