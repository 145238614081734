import React, { FC, useEffect, useState } from 'react';

import { message, Spin } from 'antd';
import { isEmpty } from 'lodash';

import styled from '@emotion/styled';

import { useGetAddEmployeeSalarySummary } from '../../../../api/peopleHooks';
import { B, DefaultBody } from '../../../../components';
import { Div } from '../../../../components/Div';
import {
  SalaryContainer,
  SalaryItem,
} from '../../../../components/SalarySlip/SalaryItem';
import theme from '../../../../theme';
import {
  PayrollItemI,
  PayrollItemTypeEnum,
} from '../../../../types/payroll.types';
import { AddEmployeeI } from '../../../../types/people.types';
import { numberThousandSeparator } from '../../../../util/utils';
import {
  getSalaryItemsByType,
  GetSalaryItemsByTypeI,
} from '../../../payroll/pages/payslip/utils/getSalaryItemsByType';
import { RemunerationModal } from '../pay-info/RemunerationModal';
import AddEmployeeFooter from './shared/AddEmployeeFooter';
import { RegulatoryComplianceDataItem } from './shared/RegulatoryComplianceDataItem';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/reducer';
import { BasedCurrencyEnum } from '../../../../types/organization.types';

const FormBody = styled.div`
  padding: 32px 32px 8px;
  border-bottom: 1px solid #e0e0e0 ;
  backgroundColor= ${theme.white};
  @media only screen and (max-width: 1200px) {
    padding: 32px 24px;
  }

  @media only screen and (max-width: 768px) {
    padding: 0px 16px;
  }
`;

const PayAmount = styled.div`
  margin-left: auto;
  display: flex;
  padding-right: 30px;
`;
const NetPay = styled.div`
  margin-left: auto;
  display: flex;
  padding-right: 32px;
`;
interface Props {
  current: number;
  onClickNext: (num: number) => void;
  onChange: (value: AddEmployeeI) => void;
  payItem: PayrollItemI[] | [];
  newEmployee: AddEmployeeI | null;
}

const SalaryDetails: FC<Props> = ({
  current,
  onClickNext,
  onChange,
  payItem,
  newEmployee,
}) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [readOnly, setReadOnly] = useState<PayrollItemTypeEnum>();
  const [selectedPayItem, setSelectedPayItem] = useState<null | PayrollItemI>(
    null,
  );
  const [salaryItemsByType, setSalaryItemsByType] =
    useState<GetSalaryItemsByTypeI>({});
  const [payItems, setPayItems] = useState<PayrollItemI[]>(payItem);

  const organizationData = useSelector(
    (state: RootState) => state.organization.organizationData,
  );

  const {
    isLoading: loadingSummary,
    mutate: getSummary,
    data: summaryData,
    error: summaryError,
  } = useGetAddEmployeeSalarySummary();

  useEffect(() => {
    if (payItem) {
      const value = {
        payItems,
        user: {
          etfNumber: newEmployee?.etfNumber,
          epfNumber: newEmployee?.epfNumber,
        },
      };
      getSummary(value);
    }
  }, [payItems]);

  useEffect(() => {
    if (!summaryError && summaryData) {
      setSalaryItemsByType(getSalaryItemsByType(payItems));
    }
  }, [summaryError, summaryData]);

  const onDeletePayItem = (itemId: number | undefined) => {
    if (itemId) setPayItems(payItems.filter((item) => item.id !== itemId));
  };

  const onClickEdit = (item: PayrollItemI) => {
    setModalVisible(true);
    setSelectedPayItem(item);
  };

  const onHandleClose = () => {
    setModalVisible(false);
    setSelectedPayItem(null);
  };

  const onClickAdd = (PayrollItemType: PayrollItemTypeEnum) => {
    setModalVisible(true);
    setReadOnly(PayrollItemType);
  };

  const RegulatoryComplianceData = summaryData
    ? Object.entries(summaryData).map(([key, v]) => ({
        payTitle: key,
        amount: v,
        isEpf: false,
        isTaxable: false,
      }))
    : [];
  return (
    <>
      <DefaultBody
        p={0}
        backgroundColor={theme.white}
        border="0.5px solid #E0E0E0"
        borderRadius={4}>
        <Spin spinning={loadingSummary}>
          <FormBody>
            <B type="b-large-semibold" pb="22px">
              Earnings
            </B>

            <SalaryContainer>
              <SalaryItem
                title="Basic Salary"
                onClickAdd={() => {
                  onClickAdd(PayrollItemTypeEnum.BASIC);
                }}
                onClickEdit={(item) => onClickEdit(item)}
                onClickDelete={(item) => onDeletePayItem(item.id)}
                salaryItems={
                  !isEmpty(salaryItemsByType)
                    ? salaryItemsByType[PayrollItemTypeEnum.BASIC]
                    : []
                }
                status="DRAFTED"
              />
            </SalaryContainer>
            <SalaryContainer>
              <SalaryItem
                title="Fixed Allowances"
                onClickAdd={() => {
                  onClickAdd(PayrollItemTypeEnum.FIXED_ALLOWANCE);
                }}
                onClickEdit={(item) => onClickEdit(item)}
                onClickDelete={(item) => onDeletePayItem(item.id)}
                salaryItems={
                  !isEmpty(salaryItemsByType)
                    ? salaryItemsByType[PayrollItemTypeEnum.FIXED_ALLOWANCE]
                    : []
                }
                status="DRAFTED"
              />
            </SalaryContainer>
            <SalaryContainer>
              <SalaryItem
                title="Variable Allowances"
                onClickAdd={() => {
                  onClickAdd(PayrollItemTypeEnum.VARIABLE_ALLOWANCE);
                }}
                onClickEdit={(item) => onClickEdit(item)}
                onClickDelete={(item) => onDeletePayItem(item.id)}
                salaryItems={
                  !isEmpty(salaryItemsByType)
                    ? salaryItemsByType[PayrollItemTypeEnum.VARIABLE_ALLOWANCE]
                    : []
                }
                status="DRAFTED"
              />
            </SalaryContainer>

            <SalaryContainer>
              <SalaryItem
                title="Non-Cash Benefits"
                onClickAdd={() => {
                  onClickAdd(PayrollItemTypeEnum.NON_CASH_BENEFITS);
                }}
                onClickEdit={(item) => onClickEdit(item)}
                onClickDelete={(item) => onDeletePayItem(item.id)}
                salaryItems={
                  !isEmpty(salaryItemsByType)
                    ? salaryItemsByType[PayrollItemTypeEnum.NON_CASH_BENEFITS]
                    : []
                }
                status="DRAFTED"
              />
            </SalaryContainer>
            <SalaryContainer>
              <SalaryItem
                onClickAdd={() => {
                  onClickAdd(PayrollItemTypeEnum.DEDUCTION);
                }}
                onClickEdit={(item) => onClickEdit(item)}
                title="Deductions"
                onClickDelete={(item) => onDeletePayItem(item.id)}
                salaryItems={
                  !isEmpty(salaryItemsByType)
                    ? salaryItemsByType[PayrollItemTypeEnum.DEDUCTION]
                    : []
                }
                status="DRAFTED"
              />
            </SalaryContainer>
            {summaryData?.netSalary != 0 ? (
              <>
                {organizationData?.peggedSettings?.pegged?.status &&
                organizationData?.peggedSettings?.peggedInfo?.basedCurrency ===
                  BasedCurrencyEnum.FOREIGN_CURRENCY ? (
                  <SalaryContainer>
                    <Div
                      borderBottom={`1px solid ${theme.gray300}`}
                      py="7px"
                      background={theme.gray100}
                      display="flex"
                      borderRadius="2px"
                      mb="22px">
                      <NetPay>
                        <B type="b-small" color={theme.gray600} pt="2px">
                          Total (
                          {
                            organizationData?.peggedSettings?.peggedInfo
                              ?.foreignCurrencyType
                          }
                          )
                        </B>{' '}
                        &nbsp;
                        <B type="b-large-semibold" color={theme.black}>
                          {numberThousandSeparator(
                            Number(summaryData?.grossSalary) -
                              Number(summaryData?.grossDeduction) || 0,
                          )}
                        </B>
                      </NetPay>
                    </Div>
                  </SalaryContainer>
                ) : (
                  <SalaryContainer>
                    <Div
                      borderBottom={`1px solid ${theme.gray300}`}
                      py="7px"
                      background={theme.gray100}
                      display="flex"
                      borderRadius="2px"
                      mb="22px">
                      <NetPay>
                        <B type="b-small" color={theme.gray600} pt="2px">
                          Net Pay
                        </B>{' '}
                        &nbsp;
                        <B type="b-large-semibold" color={theme.black}>
                          {numberThousandSeparator(summaryData?.netSalary || 0)}
                        </B>
                      </NetPay>
                    </Div>
                  </SalaryContainer>
                )}
                <br />
                {organizationData?.peggedSettings?.pegged?.status &&
                organizationData?.peggedSettings?.peggedInfo?.basedCurrency ===
                  BasedCurrencyEnum.FOREIGN_CURRENCY ? null : (
                  <>
                    {summaryData?.employeeEPF != 0 ||
                    summaryData?.employerETF != 0 ||
                    summaryData?.apitTable1Amount != 0 ? (
                      <>
                        <Div>
                          <B type="b-large-semibold" pb="22px">
                            Regulatory Compliance
                          </B>

                          <RegulatoryComplianceDataItem
                            salaryItems={RegulatoryComplianceData.filter(
                              (item) => item.payTitle === 'employeeEPF',
                            )}
                            title="Employee"
                          />
                          <br />
                          <RegulatoryComplianceDataItem
                            salaryItems={[
                              ...RegulatoryComplianceData.filter(
                                (item) =>
                                  item.payTitle === 'employerEPF' ||
                                  item.payTitle === 'employerETF',
                              ),
                            ]}
                            title="Employer"
                          />
                          <br />
                          {summaryData?.apitTable1Amount != 0 && (
                            <RegulatoryComplianceDataItem
                              salaryItems={[
                                ...RegulatoryComplianceData.filter(
                                  (item) =>
                                    item.payTitle === 'apitTable1Amount',
                                ),
                              ]}
                              title="APIT"
                            />
                          )}
                        </Div>
                        <br />
                      </>
                    ) : null}

                    <Div py={10} display="flex">
                      <B type="b-large-semibold" color={theme.black}>
                        Cost to company
                      </B>

                      <PayAmount>
                        <B type="b-large-semibold" color={theme.black}>
                          {numberThousandSeparator(
                            summaryData?.costToCompany || 0,
                          )}
                        </B>
                      </PayAmount>
                    </Div>
                  </>
                )}
              </>
            ) : null}
          </FormBody>

          <AddEmployeeFooter
            current={current}
            onClickNext={async (v) => {
              await onChange({ payItems: payItems } as AddEmployeeI);
              onClickNext(v);
            }}
            onClickBack={(v) => {
              onClickNext(v);
            }}
          />
        </Spin>
      </DefaultBody>

      {modalVisible ? (
        <RemunerationModal
          visible={modalVisible}
          readOnly={readOnly}
          payDetail={
            selectedPayItem ? { ...selectedPayItem, userId: '1' } : null
          }
          handleCancel={() => onHandleClose()}
          onSubmit={async (values) => {
            // when editing
            if (selectedPayItem) {
              const existingItem = payItems.find((item) => {
                return (
                  item.payTitle?.trim().toLowerCase() ==
                    values.payTitle?.trim().toLowerCase() &&
                  item.id !== selectedPayItem.id
                );
              });
              if (existingItem) {
                message.error(
                  'You cannot add a Pay Item with an existing name',
                );
              } else {
                const value = {
                  ...values,
                  id: selectedPayItem.id,
                };
                setPayItems([
                  ...payItems.filter((item) => item.id !== selectedPayItem.id),
                  value,
                ]);
              }
            }
            // when adding a new payItem
            else {
              const existingItem = payItems.find((item) => {
                return (
                  item.payTitle?.trim().toLowerCase() ==
                  values.payTitle?.trim().toLowerCase()
                );
              });

              const basicItems = payItems.filter((item) => {
                if (item.payTitle) {
                  return item.payTitle.trim().toLowerCase().includes('basic');
                }
              });
              const goodToAddBasicItem = (): boolean => {
                if (values.type === 'BASIC') {
                  if (
                    values.payTitle?.trim().toLowerCase().includes('basic') &&
                    basicItems.length === 0
                  ) {
                    return true;
                  } else if (
                    !values.payTitle?.trim().toLowerCase().includes('basic') &&
                    basicItems.length === 1
                  ) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  return true;
                }
              };
              if (
                basicItems.length >= 1 &&
                values.payTitle?.trim().toLowerCase().includes('basic') &&
                values.type === 'BASIC'
              ) {
                message.error('You have already added a basic salary');
              }

              if (existingItem) {
                message.error(
                  'You cannot add a Pay Item with an existing name',
                );
              }
              if (!existingItem && goodToAddBasicItem() && values) {
                setPayItems((previous) => [
                  ...previous,
                  { ...values, id: Date.now() },
                ]);
                if (values.payTitle?.trim().toLowerCase().includes('basic')) {
                  basicItems.push(values);
                }
              }
            }

            onHandleClose();
          }}
          isEmployeeAddDetailPage={true}
        />
      ) : null}
    </>
  );
};

export default SalaryDetails;
