/* eslint-disable react/jsx-no-target-blank */
import React, { FC, useEffect, useState } from 'react';
import {
  B,
  CustomDivider,
  DatePickerV2,
  DefaultBody,
  Div,
  InputNumberComp,
  InputV2,
  LabeledInputV2,
  SelectV2,
  Image,
} from '../../../../components';
import theme from '../../../../theme';
import { Col, Form, Row, Select, Tooltip } from 'antd';
import styled from '@emotion/styled';
import { EMPLOYEE_TYPES, OCCUPATION_GRADE_URL } from '../../../../configs';
import AddEmployeeFooter from './shared/AddEmployeeFooter';
import { AddEmployeeI, EmployeeI } from '../../../../types/people.types';
import { OCG_LIST } from '../../occupationClassificationGrades';
import moment from 'moment';
import { Validation } from '../../../../common/validation';
import { CheckBoxComp } from '../../../../components/v2/CheckBoxComp';
import { InfoSVG } from '../../../../assets';
import dayjs from 'dayjs';
const { Option } = Select;

const FormItem = styled(Form.Item)`
  margin-bottom: 24px;
  width: 100%;
`;
const FormBody = styled.div`
  padding: 32px 32px 8px;
  border-bottom: 1px solid #e0e0e0 ;
  backgroundColor= ${theme.white};
  @media only screen and (max-width: 1200px) {
    padding: 32px 24px;
  }
  @media only screen and (max-width: 768px) {
    padding: 0px 16px;
  }
`;

const Labeled = styled.div`
  display: flex;
  flex-direction: row;
`;

const CheckBox = styled(CheckBoxComp)`
  .ant-checkbox + span {
    color: ${theme.blue700};
    font-family: Inter;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
`;
const FormComponent = styled(Form)`
  .ant-form-item-label > label {
    font-size: 14px;
    font-weight: 600;
    color: #00318c;
  }
  margin-bottom: 50px;
  .ant-form-item-explain.ant-form-item-explain-error {
    margin-bottom: 10px;
  }
  @media (max-width: 768px) {
    margin-bottom: 80px;
  }
  .ant-form-item
    .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    display: none;
  }
`;
interface Props {
  current: number;
  onClickNext: (num: number) => void;
  employmentDetail: AddEmployeeI | null;
  onChange: (value: AddEmployeeI) => void;
}
const EmploymentDetails: FC<Props> = ({
  current,
  onClickNext,
  employmentDetail,
  onChange,
}) => {
  const [form] = Form.useForm();
  const [currentStep, setCurrentStep] = useState<number>(current);
  const [joinedDate, setJoinedDate] = useState<string>();
  const [secondaryIncome, setSecondaryIncome] = useState<boolean | undefined>(
    employmentDetail?.isSecondary ? employmentDetail?.isSecondary : false,
  );

  const setEmployeeNumber = () => {
    const x = document.getElementById('employeeNumber') as HTMLInputElement;
    form.setFieldsValue({ etfNumber: x.value, epfNumber: x.value });
  };

  const setEtfNumber = () => {
    const x = document.getElementById('epfNumber') as HTMLInputElement;
    if (x && x.value === 'N/A') {
      form.setFieldsValue({ etfNumber: x.value });
    }
  };

  useEffect(() => {
    if (employmentDetail) {
      setJoinedDate(moment(employmentDetail.joinedDate).format('YYYY-MM-DD'));

      for (const [key, value] of Object.entries(employmentDetail)) {
        if (key === 'joinedDate') {
          form.setFieldsValue({
            joinedDate: employmentDetail.joinedDate
              ? dayjs(employmentDetail.joinedDate)
              : '',
          });
        } else if (key === 'employeeHistory') {
          form.setFieldsValue({
            startDate: employmentDetail.employeeHistory?.startDate
              ? dayjs(employmentDetail.employeeHistory?.startDate)
              : '',
            employmentType: employmentDetail.employeeHistory?.employmentType
              ? employmentDetail.employeeHistory?.employmentType
              : '',
            designation: employmentDetail.employeeHistory?.designation
              ? employmentDetail.employeeHistory?.designation
              : '',
          });
        } else if (key === 'primarySalary') {
          form.setFieldsValue({
            primarySalary: Number(employmentDetail.primarySalary),
          });
        } else {
          form.setFieldsValue({ [key]: value });
        }
      }
    }
  }, [employmentDetail]);

  const onFinish = async (v: EmployeeI) => {
    const values = {
      employeeNumber: v.employeeNumber,
      occupationGrade: v.occupationGrade,
      etfNumber: v.etfNumber,
      epfNumber: v.epfNumber,
      taxNumber: v.taxNumber,
      isSecondary: secondaryIncome,
      primarySalary: Number(v.primarySalary) || null,
      joinedDate: dayjs(v.joinedDate).format('YYYY-MM-DD'),
      employeeHistory: {
        designation: v.designation,
        startDate: dayjs(v.startDate).format('YYYY-MM-DD'),
        employmentType: v.employmentType,
      },
    };
    await onChange(values as AddEmployeeI);
    onClickNext(currentStep);
  };
  return (
    <DefaultBody
      p={0}
      backgroundColor={theme.white}
      border="0.5px solid #E0E0E0"
      borderRadius={4}>
      <FormComponent
        form={form}
        name="ManagePersonalDetails"
        onFinish={(V) => onFinish(V as EmployeeI)}>
        <FormBody>
          <Row gutter={[32, 0]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <LabeledInputV2 label="Employee Number*" flexDirection="column">
                <FormItem
                  name="employeeNumber"
                  rules={[
                    { required: true, message: 'Please enter employee number' },
                    {
                      message: 'Length should be less than 12',
                      max: 12,
                    },
                  ]}>
                  <InputV2
                    size="small"
                    placeholder="Enter employee number"
                    id="employeeNumber"
                    onBlur={() => setEmployeeNumber()}
                  />
                </FormItem>
              </LabeledInputV2>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Labeled>
                <LabeledInputV2 label="Joined Date*" flexDirection="column" />
                <Tooltip title="Joined date is when the employee joined the company">
                  <Image marginLeft="5px" src={InfoSVG} />
                </Tooltip>
              </Labeled>
              <FormItem
                name="joinedDate"
                rules={[
                  { required: true, message: 'Please select the Joined Date' },
                ]}>
                <DatePickerV2
                  size="small"
                  placeholder="Select Joined Date"
                  onChange={(value) => {
                    setJoinedDate(value ? value.format('YYYY-MM-DD') : '');
                  }}
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={[32, 0]}>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <LabeledInputV2 label="EPF" flexDirection="column">
                <FormItem
                  name="epfNumber"
                  rules={[
                    {
                      type: 'string',
                      pattern: Validation.EpfEtfNumberPattern,
                      message:
                        'Please put a number without spaces or N/A in capitals',
                    },
                    {
                      type: 'string',
                      message: 'Length should be less than 6',
                      max: 6,
                    },
                  ]}>
                  <InputV2
                    size="small"
                    placeholder="Enter EPF Number"
                    id="epfNumber"
                    onBlur={() => setEtfNumber()}
                  />
                </FormItem>
              </LabeledInputV2>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <LabeledInputV2 label="ETF" flexDirection="column">
                <FormItem
                  name="etfNumber"
                  rules={[
                    {
                      type: 'string',
                      pattern: Validation.EpfEtfNumberPattern,
                      message:
                        'Please put a number without spaces or N/A in capitals',
                    },
                    {
                      type: 'string',
                      message: 'Length should be less than 6',
                      max: 6,
                    },
                  ]}>
                  <InputV2 size="small" placeholder="Enter ETF Number" />
                </FormItem>
              </LabeledInputV2>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <LabeledInputV2 label="TIN" flexDirection="column">
                <FormItem name="taxNumber">
                  <InputV2 size="small" placeholder="Enter TIN Number" />
                </FormItem>
              </LabeledInputV2>
            </Col>
          </Row>
          <Row gutter={[32, 0]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <LabeledInputV2 label="Occupation Grade *" flexDirection="column">
                <FormItem
                  name="occupationGrade"
                  rules={[
                    {
                      required: true,
                      message: 'Please select the occupation grade',
                    },
                  ]}>
                  <SelectV2
                    size="small"
                    placeholder="Select the occupation grade">
                    {OCG_LIST.sort((a, b) => (a.grade > b.grade ? 1 : -1)).map(
                      (value) => (
                        <Option key={value.id} value={value.id}>
                          {value.grade} - {value.id}
                        </Option>
                      ),
                    )}
                  </SelectV2>
                </FormItem>
              </LabeledInputV2>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <B type="b-small" color={theme.gray600} pt="25px">
                Occupational grade is a field required by Employee Provident
                Fund and Employee Trust Fund.&nbsp;
                <a href={OCCUPATION_GRADE_URL} target="_blank">
                  Learn more
                </a>
              </B>
            </Col>
          </Row>
          <Row gutter={[32, 0]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormItem name="isSecondary" valuePropName="checked">
                <CheckBox
                  onChange={(v) => setSecondaryIncome(v.target.checked)}>
                  Secondary Employee Income
                </CheckBox>
              </FormItem>
            </Col>
            {!!secondaryIncome && (
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Div display="flex">
                  <FormItem
                    label="Primary Employment Income"
                    name="primarySalary"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter primary income',
                      },
                    ]}>
                    <InputNumberComp
                      size="small"
                      placeholder="Enter Primary Income"
                    />
                  </FormItem>
                </Div>
              </Col>
            )}
          </Row>

          <CustomDivider
            mt={2}
            mb={32}
            border="0px solid"
            borderBottom="1px solid #E0E0E0"
          />
          <B type="b-default-semibold" pb="17px">
            Current Employment Details
          </B>
          <Row gutter={[32, 0]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <LabeledInputV2
                label="Designation/Job Title*"
                flexDirection="column">
                <FormItem
                  name="designation"
                  rules={[
                    { required: true, message: 'Please enter the designation' },
                  ]}>
                  <InputV2 size="small" placeholder="Enter the designation" />
                </FormItem>
              </LabeledInputV2>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <LabeledInputV2 label="Employment Type" flexDirection="column" />
              <FormItem
                initialValue="Full Time"
                name="employmentType"
                rules={[{ required: true, message: 'Select Employment type' }]}>
                <SelectV2
                  options={EMPLOYEE_TYPES}
                  placeholder="Select Employment type"
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={[32, 0]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <LabeledInputV2 label="Start Date*" flexDirection="column">
                <Labeled>
                  <FormItem
                    // label="Start Date*"
                    name="startDate"
                    rules={[{ required: true, message: 'Select start date' }]}>
                    <DatePickerV2
                      size="small"
                      placeholder="Select start date"
                      // disabledDate={(current) =>
                      //   isDisabledEndDate(
                      //     moment(current.toDate()),
                      //     moment(joinedDate),
                      //   )
                      // }
                    />
                  </FormItem>
                  <Tooltip title="Start date is when the employee assumed this designation">
                    <Image
                      marginLeft="10px"
                      marginBottom="25px"
                      src={InfoSVG}
                    />
                  </Tooltip>
                </Labeled>
              </LabeledInputV2>
            </Col>
          </Row>
        </FormBody>
      </FormComponent>
      <AddEmployeeFooter
        current={current}
        onClickNext={(v) => {
          setCurrentStep(v);
          form.submit();
        }}
        onClickBack={(v) => onClickNext(v)}
      />
    </DefaultBody>
  );
};

export default EmploymentDetails;
