import { isEmpty } from 'lodash';
import {
  PayrollItemI,
  PayrollItemTypeEnum,
} from '../../../../../types/payroll.types';

export type SalaryItemsByTypeI = PayrollItemI[];
export interface GetSalaryItemsByTypeI {
  [key: string]: SalaryItemsByTypeI;
}

export const getSalaryItemsByType = (
  salaryItems: PayrollItemI[],
): GetSalaryItemsByTypeI => {
  const types = Object.keys(PayrollItemTypeEnum) as PayrollItemTypeEnum[];
  const results: GetSalaryItemsByTypeI = {};
  types.forEach((type: PayrollItemTypeEnum) => {
    results[type] = salaryItems.filter((item) => item.type === type);
  });
  if (!isEmpty(results[PayrollItemTypeEnum.EMPLOYEE_EPF])) {
    results[PayrollItemTypeEnum.EMPLOYEE_EPF][0].payTitle = 'EPF 8%';
  }
  if (!isEmpty(results[PayrollItemTypeEnum.ETF])) {
    results[PayrollItemTypeEnum.ETF][0].payTitle = 'ETF 3%';
  }
  if (!isEmpty(results[PayrollItemTypeEnum.EMPLOYER_EPF])) {
    results[PayrollItemTypeEnum.EMPLOYER_EPF][0].payTitle = 'EPF 12%';
  }
  if (!isEmpty(results[PayrollItemTypeEnum.APIT])) {
    results[PayrollItemTypeEnum.APIT][0].payTitle = 'APIT';
  }
  if (!isEmpty(results[PayrollItemTypeEnum.APIT_TABLE_1])) {
    results[PayrollItemTypeEnum.APIT_TABLE_1][0].payTitle = 'APIT Table 1';
  }
  if (!isEmpty(results[PayrollItemTypeEnum.APIT_TABLE_2])) {
    results[PayrollItemTypeEnum.APIT_TABLE_2][0].payTitle = 'APIT Table 2';
  }

  if (!isEmpty(results[PayrollItemTypeEnum.APIT_TABLE_2_MID])) {
    results[PayrollItemTypeEnum.APIT_TABLE_2_MID][0].payTitle =
      'APIT Table 2 (Mid-cycle) ';
  }
  if (!isEmpty(results[PayrollItemTypeEnum.APIT_TABLE_5])) {
    results[PayrollItemTypeEnum.APIT_TABLE_5][0].payTitle = 'APIT Table 5';
  }
  if (!isEmpty(results[PayrollItemTypeEnum.APIT_TABLE_6])) {
    results[PayrollItemTypeEnum.APIT_TABLE_6][0].payTitle = 'APIT Table 6';
  }
  if (!isEmpty(results[PayrollItemTypeEnum.APIT_TABLE_7])) {
    results[PayrollItemTypeEnum.APIT_TABLE_7][0].payTitle = 'APIT Table 7';
  }
  return results;
};

export const getSalaryItemsByTypeForExecutives = (
  salaryItems: PayrollItemI[],
): GetSalaryItemsByTypeI => {
  const types = Object.keys(PayrollItemTypeEnum) as PayrollItemTypeEnum[];
  const results: GetSalaryItemsByTypeI = {};
  types.forEach((type: PayrollItemTypeEnum) => {
    results[type] = salaryItems.filter((item) => item.type === type);
  });
  if (!isEmpty(results[PayrollItemTypeEnum.EMPLOYEE_EPF])) {
    results[PayrollItemTypeEnum.EMPLOYEE_EPF][0].payTitle = 'EPF 10%';
  }
  if (!isEmpty(results[PayrollItemTypeEnum.ETF])) {
    results[PayrollItemTypeEnum.ETF][0].payTitle = 'ETF 3%';
  }
  if (!isEmpty(results[PayrollItemTypeEnum.EMPLOYER_EPF])) {
    results[PayrollItemTypeEnum.EMPLOYER_EPF][0].payTitle = 'EPF 15%';
  }
  if (!isEmpty(results[PayrollItemTypeEnum.APIT])) {
    results[PayrollItemTypeEnum.APIT][0].payTitle = 'APIT';
  }
  if (!isEmpty(results[PayrollItemTypeEnum.APIT_TABLE_1])) {
    results[PayrollItemTypeEnum.APIT_TABLE_1][0].payTitle = 'APIT Table 1';
  }
  if (!isEmpty(results[PayrollItemTypeEnum.APIT_TABLE_2])) {
    results[PayrollItemTypeEnum.APIT_TABLE_2][0].payTitle = 'APIT Table 2';
  }

  if (!isEmpty(results[PayrollItemTypeEnum.APIT_TABLE_2_MID])) {
    results[PayrollItemTypeEnum.APIT_TABLE_2_MID][0].payTitle =
      'APIT Table 2 (Mid-cycle) ';
  }
  if (!isEmpty(results[PayrollItemTypeEnum.APIT_TABLE_5])) {
    results[PayrollItemTypeEnum.APIT_TABLE_5][0].payTitle = 'APIT Table 5';
  }
  if (!isEmpty(results[PayrollItemTypeEnum.APIT_TABLE_6])) {
    results[PayrollItemTypeEnum.APIT_TABLE_6][0].payTitle = 'APIT Table 6';
  }
  if (!isEmpty(results[PayrollItemTypeEnum.APIT_TABLE_7])) {
    results[PayrollItemTypeEnum.APIT_TABLE_7][0].payTitle = 'APIT Table 7';
  }
  return results;
};
