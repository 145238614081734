import React, { FC } from 'react';
import { InputNumber, InputNumberProps, Typography } from 'antd';
import { space, SpaceProps } from 'styled-system';
import styled from '@emotion/styled';
import theme from '../theme';

type Props = SpaceProps &
  Omit<InputNumberProps, 'size'> & {
    label?: string;
    hint?: string;
    isCurrency?: boolean;
    flexDirection?: string;
    size?: 'small' | 'large';
  };

const StyledInput = styled(InputNumber)<Props>`
  ${(props) => props.flexDirection === 'row' && 'width: 344px;'}
  border: 1px solid ${theme.gray300};
  width: 100%;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
  line-height: 20px;
  height: ${(props) => (props.size == 'small' ? '32px' : '40px')} !important;
  input {
    height: ${(props) => (props.size == 'small' ? '32px' : '40px')} !important;
  }
  &:focus {
    border-color: ${theme.blue500};
    box-shadow: none;
  }
  &:hover {
    border: 1px solid ${theme.blue500};
    box-shadow: none;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }
  &.ant-input-number-affix-wrapper:focus,
  &.ant-input-number-affix-wrapper-focused {
    border-color: ${theme.blue500};
    box-shadow: none;
  }
  .ant-input-number[disabled] {
    background-color: ${theme.gray200};
  }
  .ant-input-number-focused {
    border-color: ${theme.blue500}!important;
    box-shadow: none !important;
  }
  &.ant-input-number-affix-wrapper:not(
      .ant-input-number-affix-wrapper-disabled
    ):hover {
    border-color: ${theme.blue500};
  }
  .ant-input-number-handler:hover .ant-input-number-handler-up-inner,
  .ant-input-number-handler:hover .ant-input-number-handler-down-inner {
    color: ${theme.blue500};
    box-shadow: none;
  }

  ${space}
`;

const Label = styled(Typography.Text)`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.blue700};
  padding-bottom: 4px;
`;

export const InputNumberComp: FC<Props> = (props) => {
  const {
    label,
    hint,
    flexDirection = 'column',
    size = 'large',
    isCurrency = false,
  } = props;
  if (isCurrency) {
    props = {
      ...props,
      formatter: (value) =>
        `${value}`.replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      parser: (value) => (value ? value.replace(/\$\s?|(,*)/g, '') : ''),
    };
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      {label && <Label>{label}</Label>}
      <StyledInput {...props} size={size} flexDirection={flexDirection} />
      {hint && <Typography.Text type="secondary">{hint}</Typography.Text>}
    </div>
  );
};
