import React, { FC, useState } from 'react';
import {
  B,
  CustomDivider,
  DefaultBody,
  InputV2,
  LabeledInputV2,
  SelectComp,
  SwitchComponent,
} from '../../../../components';
import theme from '../../../../theme';
import { Col, Form, Row, Select } from 'antd';
import styled from '@emotion/styled';
import { useGetBankList } from '../../../../api/bankHooks';
import AddEmployeeFooter from './shared/AddEmployeeFooter';
import { AddEmployeeI } from '../../../../types/people.types';
import { Validation } from '../../../../common/validation';

const { Option } = Select;

const FormItem = styled(Form.Item)`
  margin-bottom: 24px;
  width: 100%;
`;
export const FormBody = styled.div`
  padding: 32px 32px 15px;
  border-bottom: 1px solid ${theme.gray300};
  backgroundColor= ${theme.white};
  @media only screen and (max-width: 1200px) {
    padding: 32px 24px;
  }

  @media only screen and (max-width: 768px) {
    padding: 0px 16px;
  }
`;

interface Props {
  current: number;
  onClickNext: (num: number) => void;
  employmentDetail: AddEmployeeI | null;
  onChange: (value: AddEmployeeI) => void;
}
const BankingDetails: FC<Props> = ({
  current,
  onClickNext,
  employmentDetail,
  onChange,
}) => {
  const [form] = Form.useForm();
  const [currentStep, setCurrentStep] = useState<number>(current);
  const { data: banks = [] } = useGetBankList();
  const [bankCode, setBankCode] = useState('');
  const onFinish = async (values: AddEmployeeI) => {
    const value = {
      ...values,
      bankBranchId: String(values.bankBranchId),
    };

    await onClickNext(currentStep);
    onChange(value);
  };

  const [isEmployeePaidInCash, setIsEmployeePaidInCash] = useState(false);
  const handleSwitchChange = (checked: boolean) => {
    setIsEmployeePaidInCash(checked);
  };
  const bankOptions = banks.map((item) => ({
    value: item.bankCode,
    label: item.bankName,
  }));

  const getBranches = (bankCode: string) => {
    return (
      banks
        .find((item) => item.bankCode === bankCode)
        ?.bankBranches?.map((item) => ({
          value: item.id,
          code: item.branchCode,
          label: item.branchName,
        })) || []
    );
  };

  return (
    <DefaultBody
      p={0}
      backgroundColor={theme.white}
      border={`0.5px solid ${theme.gray300}`}
      borderRadius={4}>
      <Form
        form={form}
        name="ManagePersonalDetails"
        onFinish={(v) => {
          onFinish(v as AddEmployeeI);
        }}
        initialValues={{
          ...employmentDetail,
          bankAccountName: employmentDetail?.bankAccountName
            ? employmentDetail.bankAccountName
            : employmentDetail?.nameWithInitials,
        }}>
        <FormBody>
          <B type="b-default" mb="24px">
            Enter employee banking details for salary deposit
          </B>

          <Row>
            <Form.Item name="isEmployeePaidInCash" valuePropName="checked">
              <SwitchComponent
                defaultChecked={false}
                onChange={handleSwitchChange}
              />
            </Form.Item>
            <B type="b-default" color={theme.gray600} pl="16px" pt="6px">
              Employee Paid In Cash
            </B>
          </Row>
          {!isEmployeePaidInCash && (
            <>
              <Row gutter={[32, 0]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <LabeledInputV2 label="Account Name*" flexDirection="column">
                    <FormItem
                      name="bankAccountName"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter your bank account name!',
                        },
                        {
                          max: 24,
                          message: 'max characters 24.',
                        },
                      ]}>
                      <InputV2 placeholder="John Doe" size="small" />
                    </FormItem>
                  </LabeledInputV2>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <LabeledInputV2
                    label="Account Number*"
                    flexDirection="column">
                    <FormItem
                      name="bankAccountNumber"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter your bank account number!',
                        },
                        {
                          pattern: Validation.BankAccountNumberPattern,
                          message:
                            'The input is not valid bank account number!',
                        },
                      ]}>
                      <InputV2 placeholder="XXXXXXXXX723" size="small" />
                    </FormItem>
                  </LabeledInputV2>
                </Col>
              </Row>

              <Row gutter={[32, 0]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <LabeledInputV2 label="Bank*" flexDirection="column">
                    <FormItem
                      name="bankBankCode"
                      rules={[
                        {
                          required: true,
                          message: 'Please select your bank name!',
                        },
                      ]}>
                      <SelectComp
                        size="middle"
                        showSearch
                        placeholder="Select a bank name"
                        optionFilterProp="children"
                        onChange={(e) => {
                          setBankCode(String(e));
                          form.setFieldsValue({ bankBranchId: undefined });
                        }}>
                        {bankOptions.map((option) => (
                          <Option key={option.value} value={option.value}>
                            {option.value} - {option.label}
                          </Option>
                        ))}
                      </SelectComp>
                    </FormItem>
                  </LabeledInputV2>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <LabeledInputV2 label="Branch*" flexDirection="column">
                    <FormItem
                      name="bankBranchId"
                      rules={[
                        {
                          required: true,
                          message: 'Please select your branch name!',
                        },
                      ]}>
                      <SelectComp
                        size="middle"
                        showSearch
                        placeholder="Select a branch name"
                        optionFilterProp="children">
                        {getBranches(bankCode).map((option) => (
                          <Option key={option.value} value={option.value}>
                            {option.code} - {option.label}
                          </Option>
                        ))}
                      </SelectComp>
                    </FormItem>
                  </LabeledInputV2>
                </Col>
              </Row>
              <CustomDivider
                mt={3}
                mb={32}
                border="0px solid"
                borderBottom={`1px solid ${theme.gray300}`}
              />
            </>
          )}
          <Row gutter={[32, 0]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <LabeledInputV2
                label="Email address for payslip"
                flexDirection="column">
                <FormItem
                  name="paySlipMail"
                  rules={[
                    {
                      type: 'email',
                      message: 'Enter a valid email',
                    },
                  ]}>
                  <InputV2 placeholder="Enter Email address" size="small" />
                </FormItem>
              </LabeledInputV2>
            </Col>
          </Row>
        </FormBody>
      </Form>
      <AddEmployeeFooter
        current={current}
        onClickNext={(v) => {
          setCurrentStep(v);
          form.submit();
        }}
        onClickBack={(v) => {
          onClickNext(v);
        }}
      />
    </DefaultBody>
  );
};

export default BankingDetails;
