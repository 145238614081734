import React, { FC, useCallback, useEffect, useState } from 'react';
import { Dropdown, Menu, Popover, Tooltip } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { ColumnProps } from 'antd/lib/table';
import { chain, debounce, includes, uniqueId } from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import {
  useAddExternalAccountant,
  useGetAllPeople,
} from '../../api/peopleHooks';
import {
  AlertMessage,
  B,
  Can,
  Div,
  DropdownButton,
  DropdownIcon,
  H,
  InputComp,
  LinkButton,
  PrimaryButton,
  SecondaryButton,
  TableComp,
} from '../../components';
import { BannerAlert } from '../../components/alertMessage/BannerAlert';
import { AvatarComponent } from '../../components/AvatarComponent';
import PeopleTypeSelector, {
  SelectorFilterTypes,
} from '../../components/selector/PeopleTypeSelector';
import { RootState } from '../../store/reducer';
import theme from '../../theme';
import { FilterTypes, PeopleI, PeopleTableI } from '../../types/people.types';
import { Permission } from '../../types/permissions.enum';
import { isAllowed, isManager } from '../../util/permissionUtil';
import { useWindowSize } from '../../util/screenSize';
import { tableSorter } from '../../util/tableSorter';
import {
  addDotsForLongText,
  isHaveThreeDots,
  safeCheckNaN,
} from '../../util/utils';
import {
  durationByYearAndMonth,
  getDesignation,
  getEmployeeEndDate,
  getEmployeeStartDate,
  getEmpType,
} from './PeopleUtil';
import { ExternalAccountant } from './shared/ExternalAccountant';
import { DropdownBlack, SearchSVG } from '../../assets';
import AddContactModal from './AddContactModal';
import { useGetOrganization } from '../../api/organizationHooks';
import { sortEmpIds } from '../payroll/utils/sortingUtils';

const ArchivedLabel = styled.div`
  padding: 0px 8px;
  background: ${theme.gray50};
  border-radius: 4px;
  margin: auto;
  max-height: 20px;
`;

const ExternalLabel = styled.div`
  padding: 0px 8px;
  background: ${theme.warn};
  border-radius: 4px;
  margin: auto;
  max-height: 20px;
`;

const HeaderSection = styled.div`
  padding: 24px 40px 24px 32px;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    display: block;
    padding: 30px 22px 24px 16px;
  }
`;

const AllEmpDropdown = styled(Dropdown)`
  margin-left: auto;
  margin-top: 15px;
  color: ${theme.black};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  :hover {
    color: ${theme.black} !important;
  }
  .ant-dropdown .ant-dropdown-placement-bottomLeft {
    top: 255px !important;
  }
`;

const LeftSection = styled.div`
  display: flex;
  @media screen and (max-width: 768px) {
    justify-content: space-between;
    padding-bottom: 30px;
  }
`;

const PeopleHeader = styled.div`
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
`;

const RightSection = styled.div`
  display: block;
  @media screen and (min-width: 768px) {
    display: flex;
  }
`;

const MobileHideButton = styled.div`
  display: none;
  margin-left: 16px;
  margin-top: 2px;
  @media screen and (min-width: 768px) {
    display: block;
  }
`;

export const StyledTableDiv = styled.div`
  border: 1px solid ${theme.gray300};
  @media (max-width: 450px) {
    border: none;
  }
  border-radius: 4px;
  .ant-table-row {
    cursor: pointer;
  }
  .view {
    display: none;
    width: 80px;
  }
  .view-attendance-edit {
    display: none;
  }

  .ant-table-row:hover .view {
    display: block;
  }

  .ant-table-row:hover .view-attendance-edit {
    display: block;
  }
`;

const menuItems = [
  {
    key: FilterTypes.ALL,
    name: 'All People',
  },
  {
    key: FilterTypes.ACTIVE,
    name: 'Active',
  },
  {
    key: FilterTypes.ARCHIVED,
    name: 'Archived',
  },
  {
    key: FilterTypes.EXTERNAL_USERS,
    name: 'External users',
  },
  {
    key: FilterTypes.INTERNAL_USERS,
    name: 'Employee - Internal Users',
  },
];

export const PeopleTable: FC = () => {
  const navigate = useNavigate();
  const { isTablet, isDesktop, isDesktopLarge } = useWindowSize();
  const {
    isLoading,
    data: allPeoples,
    mutate: fetchPeople,
  } = useGetAllPeople();
  const { data: organizationData, mutate: getOrganizationData } =
    useGetOrganization();

  const [filter, setFilter] = useState<FilterTypes>(FilterTypes.ACTIVE);
  const [selectorFilter, setSelectorFilter] = useState<CheckboxValueType[]>([]);
  const me = useSelector((state: RootState) => state.people.me);
  const [dataSource, setDataSource] = useState<PeopleTableI[]>([]);
  const [filteredDataSource, setFilteredDataSource] = useState<PeopleTableI[]>(
    [],
  );
  const [containsDuplicateEmpIds, setContainsDuplicateEmpIds] =
    useState<boolean>(false);
  const [addContactModal, setAddContactModal] = useState<boolean>(false);
  const location = useLocation();
  const isManagerOfAUser = isManager();

  useEffect(() => {
    getOrganizationData();
    if (!isManagerOfAUser && !isAllowed(Permission.CREATE_EDIT_USERS)) {
      navigate('/home');
    } else {
      fetchPeople();
    }
  }, []);

  useEffect(() => {
    fetchPeople();
  }, [addContactModal]);

  useEffect(() => {
    const final: PeopleTableI[] = chain(allPeoples)
      .map((people) => {
        const jobStartDate = getEmployeeStartDate(people.userHistory);

        const peopleTable: PeopleTableI = {
          ...people,
          jobDesignation: getDesignation(people.userHistory),
          jobStartDateTime: jobStartDate.value,
          jobStartDateDisplay: jobStartDate.displayValue,
          jobEndDateDisplay: getEmployeeEndDate(people.userHistory),
          employeeType: getEmpType(people.userHistory),
          completedStatus: people.profileStatus?.summary.completedValue || 0,
        };
        return Filters(peopleTable);
      })
      .compact()
      .value();

    const containsDuplicates = final.some((employee, index, array) =>
      !employee.employeeNumber
        ? false
        : array.findIndex(
            (e) => e.employeeNumber === employee.employeeNumber,
          ) !== index,
    );

    setContainsDuplicateEmpIds(containsDuplicates);
    setDataSource(final);
    setFilteredDataSource(final);
  }, [allPeoples, filter, selectorFilter]);

  const Filters = (peopleTable: PeopleTableI) => {
    const allTypesFiler = () => {
      if (!selectorFilter.length) {
        return peopleTable;
      } else {
        if (
          (selectorFilter?.includes(SelectorFilterTypes.LOGIN_ALLOWED) &&
            peopleTable.isLoginAllowed) ||
          (selectorFilter?.includes(SelectorFilterTypes.PAYROLL_ALLOWED) &&
            peopleTable.isPayrollAllowed)
        ) {
          return peopleTable;
        }
      }
    };
    if (
      (filter === FilterTypes.ACTIVE && !peopleTable.isArchived) ||
      (filter === FilterTypes.ARCHIVED && peopleTable.isArchived) ||
      (filter === FilterTypes.EXTERNAL_USERS && peopleTable.isExternal) ||
      (filter === FilterTypes.INTERNAL_USERS &&
        !peopleTable.isExternal &&
        !peopleTable.isArchived) ||
      filter === FilterTypes.ALL
    ) {
      return allTypesFiler();
    }
  };

  const searchDataTableCallback = useCallback(
    debounce((searchText) => {
      const text = searchText.trim();
      if (text) {
        const filteredPeople = dataSource.filter((item) => {
          const fullText =
            item.fullName?.toLowerCase() +
            item.jobDesignation.toLowerCase() +
            item.loginEmail +
            item.employeeNumber +
            item.nicOrPassport +
            item.companyEmail +
            item.userRole +
            item.jobStartDateDisplay +
            item.jobEndDateDisplay;
          return includes(fullText, text.toLowerCase());
        });
        setFilteredDataSource(filteredPeople);
      } else {
        setFilteredDataSource(dataSource);
      }
    }, 400),
    [dataSource],
  );

  const columns: ColumnProps<PeopleTableI>[] = [
    {
      title: 'Name',
      dataIndex: 'fullName',
      width: 320,
      render: (_value: string, data) => (
        <Div display="flex" flexDirection="row">
          {data.profilePicture != null ? (
            <AvatarComponent
              src={data.profilePicture}
              shape="circle"
              border="none"
            />
          ) : (
            <AvatarComponent
              fontSize={12}
              fontWeight={600}
              shape="circle"
              title={
                data.fullName
                  ? data.fullName.charAt(0).toUpperCase()
                  : data?.preferredName?.charAt(0).toUpperCase()
              }
            />
          )}
          <Div display="flex">
            {isHaveThreeDots(data?.fullName, 30) ? (
              <B type="b-small-semibold" pt="6px" pl="12px">
                {data?.fullName ? (
                  <Tooltip placement="topLeft" title={data?.fullName}>
                    {isDesktopLarge && data?.fullName
                      ? addDotsForLongText(data?.fullName, 20)
                      : isTablet || isDesktop
                      ? addDotsForLongText(data?.fullName, 15)
                      : data?.fullName}
                  </Tooltip>
                ) : data.preferredName ? (
                  <Tooltip placement="topLeft" title={data?.preferredName}>
                    {isDesktopLarge && data?.preferredName
                      ? addDotsForLongText(data?.preferredName, 20)
                      : isTablet || isDesktop
                      ? addDotsForLongText(data?.preferredName, 15)
                      : data?.preferredName}
                  </Tooltip>
                ) : (
                  '-'
                )}
              </B>
            ) : (
              <B type="b-small-semibold" pt="6px" pl="12px" pr="8px">
                {data.fullName
                  ? data.fullName
                  : data.preferredName
                  ? data.preferredName
                  : '-'}
              </B>
            )}
            {data.isArchived && (
              <ArchivedLabel>
                <B type="b-small" color={theme.gray600}>
                  Archived
                </B>
              </ArchivedLabel>
            )}
            {data.isExternal && (
              <ExternalLabel>
                <B type="b-small" color={theme.orange300}>
                  External User
                </B>
              </ExternalLabel>
            )}
          </Div>
        </Div>
      ),
      sorter: (a, b) => {
        if (a.isPending && !b.isPending) {
          return -1;
        } else if (!a.isPending && b.isPending) {
          return 1;
        } else {
          tableSorter.defaultSort(a.fullName, b.fullName);
        }
      },
      sortDirections: ['descend', 'ascend'],
      key: 'fullName',
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Emp ID',
      dataIndex: 'employeeNumber',
      key: 'employeeNumber',
      width: 100,
      sorter: (a, b) => sortEmpIds(a.employeeNumber, b.employeeNumber),
      sortDirections: ['descend', 'ascend'],
      render: (_value: string, data) => data.employeeNumber || '-',
    },
    {
      title: 'Designation',
      dataIndex: 'jobDesignation',
      key: 'jobDesignation',
      width: 230,
      render: (_value: string, data) => (
        <>
          {isHaveThreeDots(data?.jobDesignation, 24) ? (
            <Popover
              content={data?.jobDesignation}
              trigger="hover"
              placement="topLeft">
              <p
                style={{
                  margin: 0,
                  color: `${theme.gray600}`,
                  fontSize: 12,
                  cursor: 'pointer',
                }}>
                {data?.jobDesignation
                  ? addDotsForLongText(
                      data.jobDesignation,
                      isDesktopLarge ? 24 : 12,
                    )
                  : '-'}
              </p>
            </Popover>
          ) : (
            <B type="b-small" color={theme.gray600}>
              {data.jobDesignation || '-'}
            </B>
          )}
        </>
      ),
    },
    {
      title: 'User Type',
      dataIndex: 'userType',
      key: 'userType',
      width: 130,
      render: (_value: string, data) => (
        <B type="b-small" color={theme.gray600}>
          <Tooltip
            placement="top"
            title={data.isLoginAllowed ? 'Login Allowed' : 'Login not Allowed'}>
            {data.role?.role}
          </Tooltip>
        </B>
      ),
    },
    {
      title: 'Teams',
      dataIndex: 'teams',
      key: 'teams',
      width: 180,
      render: (_value: string, data) => (
        <>
          <B type="b-small" color={theme.gray600}>
            {data.teams ? data.teams : '-'}
          </B>
        </>
      ),
    },
    {
      title: 'Experience',
      dataIndex: 'experience',
      key: 'experience',
      width: 180,
      render: (_value, data) => {
        //TODO: Need to Check with isValidMoment()
        const joinedDate = moment(data.joinedDate).isValid()
          ? moment(data.joinedDate).format('DD.MM.YYYY')
          : undefined;
        const archiveDate = moment(data.archiveDate).isValid()
          ? moment(data.archiveDate).format('DD.MM.YYYY')
          : undefined;
        return (
          <B type="b-small" color={theme.gray600}>
            <Tooltip
              placement="top"
              title={
                data.role?.role !== 'Guest Admin' &&
                `Starting date : ${
                  data.joinedDate ? joinedDate : data.jobStartDateDisplay
                }`
              }>
              {durationByYearAndMonth(
                data.joinedDate ? joinedDate : data.jobStartDateDisplay,
                data.jobEndDateDisplay !== 'On Going'
                  ? data.jobEndDateDisplay
                  : data.isArchived
                  ? archiveDate
                  : moment().format('DD.MM.YYYY'),
              )}
            </Tooltip>
          </B>
        );
      },
    },
    ...(isAllowed(Permission.CREATE_EDIT_USERS) || isManagerOfAUser
      ? [
          {
            title: '',
            dataIndex: 'viewMore',
            key: uniqueId(),
            width: 180,
            render: (_value: string, data) =>
              data.isPending && data.fullName ? (
                <B color={theme.green500} type="b-default">
                  Complete Onboarding
                </B>
              ) : data.isPending ? (
                <B color={theme.gray600} type="b-default">
                  Pending
                </B>
              ) : (
                <Link
                  className="view"
                  state={{ employee: data }}
                  to={{
                    pathname:
                      isManagerOfAUser && me?.id == data.id
                        ? `/account-details/personal-details`
                        : `/contact/personal-details/${data.id}`,
                  }}>
                  <B type="b-default" color={theme.blue500}>
                    View More
                  </B>
                </Link>
              ),
          } as ColumnProps<PeopleTableI>,
        ]
      : []),
  ];

  const menu = (
    <Menu>
      {menuItems.map((item) => (
        <Menu.Item onClick={() => setFilter(item.key)} key={item.key}>
          {item.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  const threeDotMenu = () => (
    <Menu>
      <Menu.Item onClick={() => navigate('/contact/log-trail')}>
        View LogTrail
      </Menu.Item>
    </Menu>
  );

  const getRowClassName = (record: PeopleTableI | PeopleI) => {
    if (record.isPending) {
      return 'yellow-highlight';
    }
    return '';
  };

  return (
    <>
      <div>
        {location.state && (
          <BannerAlert
            py="2px"
            message={`${location.state} Employees’ Details Upload Successfully!`}
            AlertTypes="success"
            closable
            showIcon={true}
            action={
              <LinkButton
                size="small"
                onClick={() => navigate('/contact/log-trail')}>
                View Log
              </LinkButton>
            }
          />
        )}
        <HeaderSection>
          <LeftSection>
            <PeopleHeader>
              <H type="h6">People</H>
            </PeopleHeader>
            <Div m="auto 0">
              {isAllowed(Permission.VIEW_USER_INFO) ? (
                <AllEmpDropdown overlay={menu} trigger={['click']}>
                  <a
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}>
                    <B type="b-large-semibold" display="flex">
                      {menuItems.find((item) => item.key === filter)?.name}(
                      {dataSource.length})
                      <Div pl="10px">
                        <img src={DropdownBlack} />
                      </Div>
                    </B>
                  </a>
                </AllEmpDropdown>
              ) : (
                <B type="b-small" color={theme.gray600}>
                  All Active Members
                </B>
              )}
            </Div>
          </LeftSection>

          <RightSection>
            <InputComp
              size="small"
              onChange={(e) => {
                searchDataTableCallback(e.target.value);
              }}
              placeholder="Search"
              prefix={<img src={SearchSVG} />}
            />
            <MobileHideButton>
              <PeopleTypeSelector
                onChange={(type) => {
                  setSelectorFilter(type);
                }}
              />
            </MobileHideButton>
            <Can permission={Permission.CREATE_EDIT_USERS}>
              <MobileHideButton>
                <SecondaryButton
                  size="small"
                  ml={16}
                  mr={16}
                  onClick={() => {
                    navigate('/contact/add-employees-using-file');
                  }}>
                  Add Using CSV
                </SecondaryButton>
              </MobileHideButton>

              <PrimaryButton
                style={{ marginTop: 2 }}
                onClick={() => setAddContactModal(true)}
                size="small">
                Add Contact
              </PrimaryButton>
            </Can>
            <MobileHideButton>
              <DropdownIcon
                marginLeft={16}
                marginRight={16}
                overlay={threeDotMenu}
                placement="bottomRight"
                trigger={['click']}
              />
            </MobileHideButton>
          </RightSection>
        </HeaderSection>
        {containsDuplicateEmpIds && (
          <AlertMessage
            type="error"
            title="There are multiple employees with the same employee number, please edit them."
          />
        )}
        <StyledTableDiv>
          <TableComp
            loading={isLoading}
            rowKey={(item) => item.id}
            onRow={(record: PeopleI) => {
              if (!record.isPending || record.fullName) {
                return {
                  onClick: () => {
                    isManagerOfAUser && me?.id == record.id
                      ? navigate(`/account-details/personal-details`)
                      : navigate(`/contact/personal-details/${record.id}`);
                  },
                };
              }
            }}
            rowClassName={getRowClassName}
            pagination={false}
            columns={columns}
            dataSource={filteredDataSource}
            size="large"
            fullWidth={true}
            scroll={{
              x: isTablet ? 1250 : isDesktop ? 1075 : 1075,
              y: window.innerHeight - 300,
            }}
          />
        </StyledTableDiv>
      </div>
      {addContactModal && organizationData && (
        <AddContactModal
          visible={addContactModal}
          showModal={(val: boolean) => {
            setAddContactModal(val);
            fetchPeople();
          }}
          organizationName={organizationData?.name}
        />
      )}
    </>
  );
};

export default PeopleTable;
