import React, { FC, useEffect, useState } from 'react';

import { LogTrailTable } from '../../../../components';
import { useGetAllUserLogs } from '../../../../api/peopleHooks';
import { LogTrailItemI } from '../../../../store/actions';
const AllPeopleLogTrail: FC = () => {
  const [payload, setPayload] = useState<LogTrailItemI[]>([]);
  const {
    isLoading: loadingAllUserLogs,
    data: dataAllUserLogs = [],
    mutate: getAllUserLogs,
  } = useGetAllUserLogs();

  useEffect(() => {
    getAllUserLogs();
  }, []);

  useEffect(() => {
    setPayload(
      dataAllUserLogs
        .flatMap((item) => item.userLog)
        .map((item) => {
          return {
            ...item,
            mapper: JSON.parse(item.mapper),
          };
        }) as LogTrailItemI[],
    );
  }, [dataAllUserLogs]);
  return (
    <>
      <LogTrailTable logs={payload} loading={loadingAllUserLogs} />
    </>
  );
};
export default AllPeopleLogTrail;
