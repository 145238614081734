import { css } from '@emotion/react';
import { Col, Form, Row } from 'antd';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { B, DatePickerComp, AlertMessage } from '../../../../../components';
import {
  BOC_BANK_CONFIG,
  HNB_BANK_CONFIG,
  SAMPATH_BANK_CONFIG,
  SCB_BANK_CONFIG,
  COM_BANK_CONFIG,
  HSBC_BANK_CONFIG,
} from '../../../../../configs';
import { PayrollViewDataI, PayslipI } from '../../../../../store/actions';
import { RootState } from '../../../../../store/reducer';
import theme from '../../../../../theme';
import { DATE_FORMATS } from '../../../../../configs/dateConfig';

import styled from '@emotion/styled';
import { Div } from '../../../../../components/Div';
import { ComBankTypeEnum, ComBankParams } from '../../../../../types';
import { ModalComponent } from '../../../../../components/ModalComponent';
type payrollParam = {
  payrollKey: string;
};

const ConfirmationModalForm = styled(Form)`
  .ant-form-item-label > label {
    font-size: 14px;
    font-weight: 600;
    color: ${theme.blue600};
  }
  .ant-form-item:last-child {
    margin-bottom: 0;
  }
`;

interface Props {
  handleCancel: () => void;
  visible: boolean;
  onSubmit: (values: PayslipI, selectedBank: string) => void;
  confirmEmployees: number | null;
  payrollViewData: PayrollViewDataI | null;
}
const colCss = css`
  padding: 0px 0px 12px !important;
`;
const PayrollConfirmationModal: FC<Props> = ({
  handleCancel,
  visible,
  onSubmit,
  confirmEmployees,
  payrollViewData,
}) => {
  const [isValueDateToday, setIsValueDateToday] = useState(false);
  const [isCreditDateToday, setIsCreditDateToday] = useState(false);
  const organizationData = useSelector(
    (state: RootState) => state.organization.organizationData,
  );
  const navigate = useNavigate();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  useEffect(() => {
    if (!organizationData?.bankDetails?.bankName) {
      setShowErrorMessage(true);
    }
  }, [organizationData?.bankDetails]);

  const isToday = (value: moment.Moment | null): boolean => {
    return Boolean(
      value &&
        value.format(DATE_FORMATS.iso_date_format) ===
          moment().format(DATE_FORMATS.iso_date_format),
    );
  };

  const [form] = Form.useForm();
  const params = useParams<payrollParam>();
  function onFinish(values: PayslipI) {
    switch (organizationData?.bankDetails?.bankCode) {
      case SAMPATH_BANK_CONFIG.code:
        const valueSampathBank = {
          payrollId: params.payrollKey,
        };
        onSubmit(valueSampathBank, SAMPATH_BANK_CONFIG.value);
        break;
      case BOC_BANK_CONFIG.code:
        const valueBOC = {
          ...values,
          valueDate: moment(values.valueDate).format('YYYY-MM-DD'),
          payrollId: params.payrollKey,
        };
        onSubmit(valueBOC, BOC_BANK_CONFIG.value);
        break;
      case SCB_BANK_CONFIG.code:
        const valueSCB = {
          payrollId: params.payrollKey,
        };
        onSubmit(valueSCB, SCB_BANK_CONFIG.value);
        break;
      case HNB_BANK_CONFIG.code:
        const value = {
          ...values,
          valueDate: moment(values.valueDate).format('YYYY-MM-DD'),
          dateOfCrediting: moment(values.dateOfCrediting).format('YYYY-MM-DD'),
          payrollId: params.payrollKey,
        };
        onSubmit(value, HNB_BANK_CONFIG.value);
        break;
      case HSBC_BANK_CONFIG.code:
        const valueHSBC = {
          ...values,
          dateOfCrediting: moment(values.dateOfCrediting).format('YYYY-MM-DD'),
          payrollId: params.payrollKey,
        };
        onSubmit(valueHSBC, HSBC_BANK_CONFIG.value);
        break;
      case COM_BANK_CONFIG.code:
        const valueCom = {
          ...values,
          payrollId: params.payrollKey,
          dateOfCrediting: moment(values.dateOfCrediting).format('YYYY-MM-DD'),
        };

        onSubmit(
          valueCom,
          organizationData?.bankDetails?.comBankType == ComBankTypeEnum.DIGITAL
            ? ComBankParams.CMBD
            : organizationData?.bankDetails?.comBankType ==
              ComBankTypeEnum.PAYMASTER
            ? ComBankTypeEnum.PAYMASTER
            : ComBankParams.CMBO,
        );
        break;
      default:
    }
  }

  return (
    <ModalComponent
      form={form}
      centered
      disabled={!organizationData?.bankDetails?.bankName}
      submitText="Confirm"
      width={540}
      visible={visible}
      onCancel={handleCancel}
      title={<B type="b-large-semibold">Confirmation</B>}>
      <ConfirmationModalForm
        name="payrollConfirmation"
        layout="vertical"
        onFinish={(values) => {
          onFinish(values as PayslipI);
        }}
        form={form}>
        {showErrorMessage && (
          <AlertMessage
            type="error"
            title="Organization Bank is not set"
            onAction={() => {
              navigate('/bank-information');
            }}
            actionLabel={
              <B
                type="b-default-semibold"
                color={theme.blue500}
                pr="5px"
                pt="1px">
                Manage Bank Details
              </B>
            }
            onClose={() => {
              setShowErrorMessage(false);
            }}
          />
        )}
        <B
          type="b-default"
          pb="16px"
          pt={!organizationData?.bankDetails?.bankName ? '16px' : '0px'}>
          Confirm these details are correct.
        </B>
        <Div
          background={theme.gray100}
          borderRadius="4px"
          pt="16px"
          px="16px"
          pb="4px">
          <Row>
            <Col span={10} css={colCss}>
              <B type="b-default" color={theme.gray600}>
                Payroll Name
              </B>
            </Col>
            <Col css={colCss}>
              <B type="b-default">{payrollViewData?.name}</B>
            </Col>
          </Row>
          <Row>
            <Col span={10} css={colCss}>
              <B type="b-default" color={theme.gray600}>
                Period
              </B>
            </Col>
            <Col css={colCss}>
              <B type="b-default">
                {moment(payrollViewData?.payrollStartDate).format('MMMM Do') +
                  ' - ' +
                  moment(payrollViewData?.payrollEndDate).format('MMMM Do')}
              </B>
            </Col>
          </Row>
          <Row>
            <Col span={10} css={colCss}>
              <B type="b-default" color={theme.gray600}>
                Remark
              </B>
            </Col>
            <Col css={colCss}>
              <B type="b-default">
                {payrollViewData?.defaultRemark
                  ? payrollViewData?.defaultRemark
                  : '-'}
              </B>
            </Col>
          </Row>
          <Row>
            <Col span={10} css={colCss}>
              <B type="b-default" color={theme.gray600}>
                Number of Employees
              </B>
            </Col>
            <Col css={colCss}>
              <B type="b-default">{confirmEmployees}</B>
            </Col>
          </Row>
          <Row>
            <Col span={10} css={colCss}>
              <B type="b-default" color={theme.gray600}>
                Bank Name
              </B>
            </Col>
            <Col css={colCss}>
              <B type="b-default">
                {organizationData?.bankDetails?.bankName || '-'}
              </B>
            </Col>
          </Row>
        </Div>
        <B type="b-default" pt="16px" pb="16px">
          I understand that I cannot delete this payrun once I confirm.
        </B>
        {organizationData?.bankDetails ? (
          <>
            {HNB_BANK_CONFIG.code ===
              Number(organizationData.bankDetails.bankCode) ||
            COM_BANK_CONFIG.code ===
              Number(organizationData.bankDetails.bankCode) ||
            HSBC_BANK_CONFIG.code ===
              Number(organizationData.bankDetails.bankCode) ? (
              <>
                <Form.Item
                  label="Date of crediting"
                  name="dateOfCrediting"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your date of crediting!',
                    },
                  ]}>
                  <DatePickerComp
                    placeholder="Select the date"
                    onChange={(value) => {
                      setIsCreditDateToday(isToday(moment(value.toDate())));
                    }}
                  />
                </Form.Item>
                {HNB_BANK_CONFIG.code ===
                  Number(organizationData.bankDetails.bankCode) && (
                  <Form.Item
                    label="Value Date"
                    name="valueDate"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your value Date!',
                      },
                    ]}>
                    <DatePickerComp
                      placeholder="Select the date"
                      onChange={(value) => {
                        setIsValueDateToday(isToday(moment(value.toDate())));
                      }}
                    />
                  </Form.Item>
                )}
                {isValueDateToday || isCreditDateToday ? (
                  <Div mb="16px">
                    <AlertMessage
                      title="You have to upload this bank file before 1pm today!"
                      type="warn"
                    />
                  </Div>
                ) : null}
              </>
            ) : null}
          </>
        ) : null}
      </ConfirmationModalForm>
    </ModalComponent>
  );
};
export default PayrollConfirmationModal;
