import { Col, Row } from 'antd';
import React, { FC } from 'react';
import { SummaryContainer, SummaryItem } from './SummaryContainer';
import { v4 as uuidv4 } from 'uuid';
import {
  DeductionGroupI,
  PublishSummaryPayrollI,
} from '../../../../store/actions';
import { Div } from '../../../../components';
import {
  PayrollItemTypeEnum,
  PayrunType,
} from '../../../../types/payroll.types';
import styled from '@emotion/styled';

interface Props {
  allPublishPayrollSummary: PublishSummaryPayrollI | null;
  haveDeduction: boolean;
  payrunType?: string | null;
}

const StyledDiv = styled(Div)`
  @media print {
    width: 600px;
  }
`;

const LeftCol = styled(Col)`
  @media print {
    width: 800px;
  }
  padding-right: 32px;
`;

const RightCol = styled(Col)`
  @media print {
    width: 570px;
  }
`;

export const SalaryJournal: FC<Props> = ({
  allPublishPayrollSummary,
  haveDeduction,
  payrunType,
}) => {
  const allowanceGroup: DeductionGroupI[] =
    payrunType === PayrunType.MID_CYCLE_PAYRUN
      ? allPublishPayrollSummary?.allowanceGroup.filter(
          (item) =>
            item.type !== PayrollItemTypeEnum.FIXED_ALLOWANCE &&
            item.type !== PayrollItemTypeEnum.NON_CASH_BENEFITS,
        ) || []
      : allPublishPayrollSummary?.allowanceGroup || [];

  return (
    <StyledDiv pt="24px" className="testing">
      <Row>
        <LeftCol md={24} lg={12} xl={12} xxl={12}>
          <SummaryContainer headingTitle="Earnings">
            {payrunType === PayrunType.MONTHLY_PAYRUN && (
              <SummaryItem
                typeName="Basic"
                payAmount={Number(
                  allPublishPayrollSummary?.basic
                    ? allPublishPayrollSummary?.basic
                    : 0,
                )}
              />
            )}
            {allowanceGroup.map((item) => {
              return (
                <SummaryItem
                  key={uuidv4()}
                  typeName={item.name ? item.name : '-'}
                  payAmount={Number(item.amount ? item.amount : 0)}
                />
              );
            })}
            <SummaryItem
              typeName="Lump Sum"
              payAmount={Number(
                allPublishPayrollSummary?.lumpSumTotal
                  ? allPublishPayrollSummary?.lumpSumTotal
                  : 0,
              )}
            />
          </SummaryContainer>
          {haveDeduction ? null : (
            <SummaryContainer headingTitle="Deductions">
              {allPublishPayrollSummary?.deductionsGroup.map((item) => {
                return (
                  <SummaryItem
                    key={uuidv4()}
                    typeName={item.name ? item.name : '-'}
                    payAmount={Number(item.amount ? item.amount : 0)}
                  />
                );
              })}
            </SummaryContainer>
          )}

          <SummaryContainer headingTitle="Total">
            <SummaryItem
              typeName="Net Salary Amount"
              payAmount={Number(
                allPublishPayrollSummary?.netSalary
                  ? allPublishPayrollSummary?.netSalary
                  : 0,
              )}
            />
            <SummaryItem
              typeName="Total Cost to company"
              payAmount={Number(
                allPublishPayrollSummary?.costToCompany
                  ? allPublishPayrollSummary?.costToCompany
                  : 0,
              )}
            />
          </SummaryContainer>
        </LeftCol>

        <RightCol md={24} lg={12} xl={12} xxl={12}>
          <SummaryContainer headingTitle="Other">
            <SummaryItem
              typeName="EPF Employee contrubution 8%"
              payAmount={Number(
                allPublishPayrollSummary?.employeeEpf
                  ? allPublishPayrollSummary?.employeeEpf
                  : 0,
              )}
            />
            <SummaryItem
              typeName="EPF Employer contrubution 12%"
              payAmount={Number(
                allPublishPayrollSummary?.employerEpf
                  ? allPublishPayrollSummary?.employerEpf
                  : 0,
              )}
            />
            <SummaryItem
              typeName="ETF contrubution 3%"
              payAmount={Number(
                allPublishPayrollSummary?.etf
                  ? allPublishPayrollSummary?.etf
                  : 0,
              )}
            />
            <SummaryItem
              typeName="APIT"
              payAmount={Number(
                allPublishPayrollSummary?.APIT
                  ? allPublishPayrollSummary?.APIT
                  : 0,
              )}
            />
          </SummaryContainer>
        </RightCol>
      </Row>
    </StyledDiv>
  );
};

export default SalaryJournal;
