import React, { FC, useState, useEffect, useRef } from 'react';
import { Col, DatePicker, Menu, Row } from 'antd';
import moment, { Moment } from 'moment';
import { DataRangeI } from '../../types/report.types';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { PayrollCalenderSVG } from '../../assets';

interface Props {
  onChange: (value: DataRangeI) => void;
  defaultStartDeductMonths?: number | null;
}
const { RangePicker } = DatePicker;

const MenuCompo = styled(Menu)`
  .ant-menu-item {
    margin: 0px !important;
    height: 32px;
    line-height: 32px;
  }

  .ant-menu-item-selected a,
  .ant-menu-item-selected a:hover {
    color: #0052ea !important;
    background: #e6eefd !important;
  }
  .ant-menu-item:hover {
    color: #0052ea;
  }
  .ant-menu-item::after {
    border-right: 0px !important;
  }
`;
const Icons = styled.img`
  border-radius: 4px !important;
`;

const PickerComponent = styled(Row)`
  border-radius: 4px !important;
`;
const Columns = styled(Col)<{ startDate: Moment | null }>`
  border-right: ${(props) =>
    props.startDate != null ? 'none' : ' 1px solid #dcdddf'};
  padding: 24px 0px 18px;
  border-radius: ${(props) =>
    props.startDate != null ? '4px' : 'unset'}!important;
`;
const RangePickerComp = styled(RangePicker)`
  margin-left: 16px !important;
  @media only screen and (max-width: 780px) {
    margin-left: 0px !important;
    margin-top: 5px !important;
    display: flex;
    justify-content: center;
  }
  height: 32px;
  width: 270px !important;
  border-color: #e0e0e0;
  box-shadow: none;
  :hover,
  :focus {
    color: #000000 !important;
    border-color: #e0e0e0 !important;
  }
  .ant-picker-focused {
    border-color: #e0e0e0 !important;
  }
`;
const RangePickerComponent: FC<Props> = ({
  onChange,
  defaultStartDeductMonths = null,
}) => {
  const [startDate, setStartDate] = useState<Moment | null>(
    defaultStartDeductMonths === null
      ? null
      : moment().subtract(defaultStartDeductMonths, 'month').startOf('month'),
  );
  const [endDate, setEndDate] = useState<Moment | null>(
    defaultStartDeductMonths === null ? null : moment().endOf('day'),
  );
  const [pickerVisible, setPickerVisible] = useState<boolean>(false);
  const pickerRef = useRef<any>(null);

  const closePicker = () => {
    if (startDate && endDate) {
      setPickerVisible(false);
      if (pickerRef.current) {
        pickerRef.current.blur();
      }
    } 
  };

  useEffect(() => {
    onChange({
      startDate,
      endDate,
    });
    closePicker();
  }, [startDate, endDate]);

  const panelRender = (
    originalPanel:
      | boolean
      | React.ReactChild
      | React.ReactFragment
      | React.ReactPortal
      | null
      | undefined,
  ) => {
    return (
      <PickerComponent>
        <Columns startDate={startDate}>
          <MenuCompo
            style={{ width: 159 }}
            defaultSelectedKeys={['6']}
            mode="inline">
            <Menu.Item
              key="1"
              onClick={() => {
                setStartDate(moment().startOf('day'));
                setEndDate(moment().endOf('day'));
              }}>
              Today
            </Menu.Item>
            <Menu.Item
              key="2"
              onClick={() => {
                setStartDate(moment().subtract(1, 'days').startOf('day'));
                setEndDate(moment().subtract(1, 'days').endOf('day'));
              }}>
              Yesterday
            </Menu.Item>
            <Menu.Item
              key="3"
              onClick={() => {
                setStartDate(moment().subtract(6, 'days').startOf('day'));
                setEndDate(moment().endOf('day'));
              }}>
              Last 7 Days
            </Menu.Item>
            <Menu.Item
              key="4"
              onClick={() => {
                setStartDate(moment().subtract(29, 'days').startOf('day'));
                setEndDate(moment().endOf('day'));
              }}>
              Last 30 Days
            </Menu.Item>
            <Menu.Item
              key="5"
              onClick={() => {
                setStartDate(moment().subtract(1, 'month').startOf('month'));
                setEndDate(moment().endOf('day'));
              }}>
              Last Month
            </Menu.Item>
            <Menu.Item
              key="6"
              onClick={() => {
                setStartDate(moment().subtract(5, 'month').startOf('month'));
                setEndDate(moment().endOf('day'));
              }}>
              Last 6 Month
            </Menu.Item>
            <Menu.Item
              key="7"
              onClick={() => {
                setEndDate(null);
                setStartDate(null);
              }}>
              Custom Range
            </Menu.Item>
          </MenuCompo>
        </Columns>
        {!startDate && !endDate ? <Col>{originalPanel}</Col> : null}
      </PickerComponent>
    );
  };

  return (
    <>
      <RangePickerComp
        ref={(ref) => {
          pickerRef.current = ref;
        }}
        inputReadOnly={true}
        open={pickerVisible}
        onFocus={() => setPickerVisible(true)}
        onClick={() => setPickerVisible(true)}
        onOpenChange={(open) => {
          if (!open) {
            closePicker();
          }
        }}
        onChange={(e) => {
          setStartDate(e ? moment(e?.[0].toDate()) : null);
          setEndDate(e ? moment(e?.[1].toDate()) : null);
        }}
        value={[dayjs(startDate?.toDate()), dayjs(endDate?.toDate())]}
        panelRender={panelRender}
        suffixIcon={<Icons src={PayrollCalenderSVG} />}
      />
    </>
  );
};

export default RangePickerComponent;
