import { saveAs } from 'file-saver';
import * as ExcelJS from 'exceljs';
import moment from 'moment';
import {
  DeductionGroupI,
  PublishSummaryPayrollI,
} from '../../../store/actions';
import { PayrollItemTypeEnum, PayrunType } from '../../../types/payroll.types';
import {
  addFullBorders,
  boldTextsByWorksheet,
  centerTextsByWorksheet,
  currencyFormat,
} from '../../../util/exceljs.util';

export const generateJournal = (
  payload: PublishSummaryPayrollI,
  payrunType?: string | null,
) => {
  const allowanceGroup: DeductionGroupI[] =
    payrunType === PayrunType.MID_CYCLE_PAYRUN
      ? payload.allowanceGroup.filter(
          (item) =>
            item.type !== PayrollItemTypeEnum.FIXED_ALLOWANCE &&
            item.type !== PayrollItemTypeEnum.NON_CASH_BENEFITS,
        ) || []
      : payload.allowanceGroup || [];

  const workbook = new ExcelJS.Workbook();

  const worksheet = workbook.addWorksheet('SalaryJournal');
  worksheet.columns = [
    { key: 'A', width: 25 },
    { key: 'B', width: 20 },
    { key: 'C' },
    { key: 'D' },
    { key: 'E', width: 25 },
    { key: 'F', width: 20 },
  ];

  worksheet.addRows([
    { A: 'Salary Journal' },
    [],
    [],
    { A: 'No of Employees', B: payload.noOfEmployees },
    {
      A: 'Payroll Start Date',
      B: moment(payload.payrollStartDate).format('YYYY MMMM DD'),
    },
    {
      A: 'Payroll End Date',
      B: moment(payload.payrollEndDate).format('YYYY MMMM DD'),
    },
    [],
    [],
  ]);
  worksheet.mergeCells('A1:F1');
  worksheet.getCell('B5').alignment = { horizontal: 'right' };
  worksheet.getCell('B6').alignment = { horizontal: 'right' };

  if (payload.deductionsGroup.length != 0) {
    worksheet.addRow({ A: 'Earnings', E: 'Deductions' });

    addFullBorders(worksheet, ['A9', 'E9']);

    const maxLength = Math.max(
      allowanceGroup.length,
      payload.deductionsGroup.length,
    );
    let j = 0; //this variable is used for handle the iteration
    if (payrunType != PayrunType.MID_CYCLE_PAYRUN) {
      j = 1;
      const basicRow = worksheet.addRow({
        A: 'Basics',
        B: payload.basic,
        E: payload.deductionsGroup[0].name,
        F: payload.deductionsGroup[0].amount,
      });
      const basicRowNumber = basicRow.number;
      addFullBorders(worksheet, [
        `A${basicRowNumber}`,
        `B${basicRowNumber}`,
        `E${basicRowNumber}`,
        `F${basicRowNumber}`,
      ]);
      currencyFormat(worksheet, [`B${basicRowNumber}`, `F${basicRowNumber}`]);
    }

    for (let i = 0; i <= maxLength; i++) {
      const allowance = allowanceGroup[i] || { name: '', amount: '' }; // Fallback if undefined
      const deduction = payload.deductionsGroup[j] || {
        name: '',
        amount: '',
      };

      const currentRow = worksheet.addRow({
        A: allowance.name,
        B: allowance.amount,
        E: deduction.name,
        F: deduction.amount,
      });
      if (i === allowanceGroup.length) {
        currentRow.getCell('A').value = 'Lump Sum';
        currentRow.getCell('B').value = payload.lumpSumTotal;
      }

      const rowNumber = currentRow.number;

      if (i <= allowanceGroup.length) {
        addFullBorders(worksheet, [`A${rowNumber}`, `B${rowNumber}`]);
      }
      if (i < payload.deductionsGroup.length - j) {
        addFullBorders(worksheet, [`E${rowNumber}`, `F${rowNumber}`]);
      }
      currencyFormat(worksheet, [`B${rowNumber}`, `F${rowNumber}`]);
      j++;
    }
    worksheet.mergeCells('A9:B9');
    worksheet.mergeCells('E9:F9');
    //if the salary cycle doesn't have deductions
  } else {
    worksheet.addRow({ A: 'Earnings' });
    addFullBorders(worksheet, ['A9']);

    if (payrunType != PayrunType.MID_CYCLE_PAYRUN) {
      const basicRow = worksheet.addRow({
        A: 'Basics',
        B: payload.basic,
      });
      const basicRowNumber = basicRow.number;
      addFullBorders(worksheet, [`A${basicRowNumber}`, `B${basicRowNumber}`]);
      currencyFormat(worksheet, [`B${basicRowNumber}`]);
    }

    for (let i = 0; i <= allowanceGroup.length; i++) {
      const allowance = allowanceGroup[i] || { name: '', amount: '' };
      const currentRow = worksheet.addRow({
        A: allowance.name,
        B: allowance.amount,
      });
      if (i === allowanceGroup.length) {
        currentRow.getCell('A').value = 'Lump Sum';
        currentRow.getCell('B').value = payload.lumpSumTotal;
      }
      const rawNumber = currentRow.number;

      addFullBorders(worksheet, [`A${rawNumber}`, `B${rawNumber}`]);
      currencyFormat(worksheet, [`B${rawNumber}`]);
    }
    worksheet.mergeCells('A9:B9');
  }

  const rows = worksheet.addRows([[], [], { A: 'Total', E: 'Other' }]);
  const rowNumber = rows[2].number;
  worksheet.mergeCells(`A${rowNumber}:B${rowNumber}`);
  worksheet.mergeCells(`E${rowNumber}:F${rowNumber}`);
  addFullBorders(worksheet, [`A${rowNumber}`, `E${rowNumber}`]);

  centerTextsByWorksheet(worksheet, [
    'A1',
    'A9',
    'E9',
    `A${rowNumber}`,
    `E${rowNumber}`,
  ]);

  boldTextsByWorksheet(worksheet, [
    'A1',
    'A9',
    'E9',
    `A${rowNumber}`,
    `E${rowNumber}`,
  ]);

  const rowArray = worksheet.addRows([
    {
      A: 'Net Salary Amount',
      B: payload.netSalary,
      E: 'EPF Employee contrubution 8%',
      F: payload.employeeEpf,
    },
    {
      A: 'Total Cost to Company',
      B: payload.costToCompany,
      E: 'Employer EPF 12%',
      F: payload.employerEpf,
    },
    {
      E: 'ETF contrubution 3%',
      F: payload.etf,
    },
    { E: 'APIT', F: payload.APIT },
  ]);
  addFullBorders(worksheet, [
    `A${rowArray[0].number}`,
    `B${rowArray[0].number}`,
    `E${rowArray[0].number}`,
    `F${rowArray[0].number}`,
  ]);
  addFullBorders(worksheet, [
    `A${rowArray[1].number}`,
    `B${rowArray[1].number}`,
    `E${rowArray[1].number}`,
    `F${rowArray[1].number}`,
  ]);
  addFullBorders(worksheet, [
    `E${rowArray[2].number}`,
    `F${rowArray[2].number}`,
  ]);
  addFullBorders(worksheet, [
    `E${rowArray[3].number}`,
    `F${rowArray[3].number}`,
  ]);
  //for mid cycle payrun

  rowArray.forEach((row) => {
    const rowNumber = row.number;
    currencyFormat(worksheet, [`B${rowNumber}`, `F${rowNumber}`]);
  });

  workbook.xlsx.writeBuffer().then(function (buffer) {
    saveAs(
      new Blob([buffer], { type: 'application/octet-stream' }),
      `Salary_Journal_${moment().format('DD-MM-YYYY')}.xlsx`,
    );
  });
};
