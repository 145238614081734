import React, { FC, useEffect, useState } from 'react';
import { AddManuallyLayout } from '../../shared/addEmployeeManually/AddManuallyLayout';
import PersonalDetails from './PersonalDetails';
import EmploymentDetails from './EmploymentDetails';
import BankingDetails from './BankingDetails';
import SalaryDetails from './SalaryDetails';
import Confirmation from './Confirmation';
import Completed from './Completed';
import { AddEmployeeI } from '../../../../types/people.types';
import { usePostNewEmployee } from '../../../../api/peopleHooks';
import { steps } from '../../shared/addEmployeeManually/StepsComponent';
import { useLocation } from 'react-router-dom';

const AddEmployeeManuallyPage: FC = () => {
  const [current, setCurrent] = useState<number>(0);
  const [newEmployee, setNewEmployee] = useState<AddEmployeeI | null>(null);
  const updatedSteps = [...steps];
  const location = useLocation();
  const onClickNext = (num: number) => {
    if (current != 4) {
      updatedSteps[current].completed = true;
    }
    setCurrent(num);
  };
  const onClickStep = (value: number) => {
    setCurrent(value);
  };
  const {
    isLoading: loadingPostNewEmployee,
    error: errorPostNewEmployee,
    mutate: postNewEmployee,
    data: postNewEmployeeData,
  } = usePostNewEmployee();

  // Check for the reset state in location for add another employee
  useEffect(() => {
    if (location.state?.resetToStep === 1) {
      setCurrent(0);
      setNewEmployee(null);
    }
  }, [location.state]);

  useEffect(() => {
    if (postNewEmployeeData && !errorPostNewEmployee) {
      onClickNext(current + 1);
    }
  }, [postNewEmployeeData]);

  const renderSwitchSteps = (currentNumber: number) => {
    switch (currentNumber) {
      case 1:
        return (
          <EmploymentDetails
            current={current}
            onClickNext={onClickNext}
            employmentDetail={newEmployee}
            onChange={(v) => {
              setNewEmployee({ ...newEmployee, ...(v as AddEmployeeI) });
            }}
          />
        );
      case 2:
        return (
          <SalaryDetails
            current={current}
            onClickNext={onClickNext}
            payItem={
              newEmployee && newEmployee.payItems != undefined
                ? newEmployee.payItems
                : []
            }
            newEmployee={newEmployee}
            onChange={(v) => {
              setNewEmployee({ ...newEmployee, ...(v as AddEmployeeI) });
            }}
          />
        );
      case 3:
        return (
          <BankingDetails
            current={current}
            onClickNext={onClickNext}
            employmentDetail={newEmployee}
            onChange={(v) => {
              setNewEmployee({ ...newEmployee, ...(v as AddEmployeeI) });
            }}
          />
        );
      case 4:
        return (
          <Confirmation
            current={current}
            onClickNext={(v) => {
              if (newEmployee != null && current < v) {
                newEmployee.payItems.forEach((item) => {
                  delete item['id'];
                });
                postNewEmployee(newEmployee);
              }
              if (current > v) {
                onClickNext(v);
              }
            }}
            employmentDetail={newEmployee}
          />
        );
      case 5:
        return (
          <Completed
            employmentDetail={postNewEmployeeData}
            loadingPostNewEmployee={loadingPostNewEmployee}
          />
        );
      default:
        return (
          <PersonalDetails
            personalDetail={newEmployee}
            current={current}
            onClickNext={onClickNext}
            onChange={(v) => {
              setNewEmployee({ ...newEmployee, ...v });
            }}
          />
        );
    }
  };
  return (
    <>
      <AddManuallyLayout current={current} setCurrent={onClickStep}>
        {renderSwitchSteps(current)}
      </AddManuallyLayout>
    </>
  );
};

export default AddEmployeeManuallyPage;
